import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";
import BreadCrum from "../../Components/BoilerPlate/BreadCrum";
import { Button } from "react-bootstrap";
import ReactVirtualized from "./ReactVirtualized";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExcelRenderer } from "react-excel-renderer";
import SnapshotService from "../../Services/Snapshot/SnapshotService";
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import {
  CREATE_BUSSINESS_SNAPSHOT_CODE,
  CREATE_REIMBUSRMENT_SNAPSHOT_CODE,SNAPSHOT_LEDGER_ENTRIES_CODE,VIEW_ONLY_SNAPSHOT_CODE
} from "../../Helpers/Constants";
import { changeDateFormate, changeDateTimeFormat, displayDateFormate } from "../../Helpers/DateFormat";
import {
  checkValidGSTNo,
  validateCreateEvidenceWithReadyState,validateExcelInventoryModeData
} from "../../Views/Snapshot/Validation";
import { confirm } from "../../Components/BoilerPlate/Confirm";
import { toast } from "react-toastify";
import { roundOffValue } from "../../Views/Snapshot/Roundoff";
import { confirm as oldconfirm } from '../../Components/BoilerPlate/Confirm';
import loaderw from '../../Assets/img/buktec-preloader.gif'
import Gstr2bRouteLeavingGuard from "../../Utils/Gstr2bRouteLeavingGuard";
import excelFile from "../../Assets/img/Buktec-Snapshot-Excel-Import-Inventory-Sample.xlsx";


const masterPayload = {
  tags: [],
  categories: [],
  merchants: [],
};

const tableH = [
  "SR. NO.",
  "VOUCHER TYPE",
  "BILL NO.",
  "BILL DATE",
  "PARTY NAME",
  "GSTIN",
  "STATE-POS",
  "LOCATION / GODOWN",
  "PRODUCTS",
  "HSN",
  "GST RATE",
  "QTY",
  "RATE",
  "PER",
  "TAXABLE VALUE",
  "CGST",
  "SGST",
  "IGST",
  "TOTAL VALUE",
];

const BATCHSIZE = 400;
const RECORD_LIMIT = 1000;

class ExcelInventory extends Component {
  constructor(props) {
    super(props);

    this.PERMISSION_LIST = this.props.features_permissions_list;
        this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(
          CREATE_BUSSINESS_SNAPSHOT_CODE
        );
        this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(
          CREATE_REIMBUSRMENT_SNAPSHOT_CODE
        );
        this.SNAPSHOT_LEDGER_ENTRIES_CODE = this.PERMISSION_LIST.includes(SNAPSHOT_LEDGER_ENTRIES_CODE);
        this.VIEW_ONLY_SNAPSHOT = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);

    this.state = {
      save_and_redirect:false,
      next_route : false,
      closed: false,
      is_dirty : false,
      voucherTypeList : [],
      line_items_list:[],
      ledgerNameList: {
				ledger_list: [],
				last_updated_at: null
			},
      item_ledger_list:[],
      excelData: [],
      isUploading: false,
      uploading_file_percent: 0,
      isDownload: false,
      isReady: false,
      isSelectFile: false,
      selectFileError: "",
      merchant_option: {},
      categories: [],
      merchants: [],
      all_merchant_list: [],
      all_old_merchant_list: [],
      snapshot_types: [],
      tags: [],

      editableRowIndex: null,
      //  editableColumnIndex: null,
      newCategoryName: "",
      // shortcut key state
      scrollStep: 100, // Adjust this value as needed
      scrollTop: 0,
      scrollLeft: 0,
    };

    this.scrollRef = React.createRef();
  }

  saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
		});
	}
  closeAction = () => {
		this.setState({ closed: false })
	}

  customStyles = {
      control: (provided) => ({
        ...provided,
        minWidth: 200,
      }),
    };

    getItemLedgers = () => {
      SnapshotService.getItemLedgers().then((data) => {
        if (data.response_code == 200) {
          this.setState({
            item_ledger_list: data.result.item_ledgers,
          });
        }
      });
    }

    getLedgerNameList = async () => {
        await MoneyLinkService.getLedgerNameList().then(data => {
          if (data.response_code == 200) {
            this.setState({ ledgerNameList: data.result })
          }
        })
      }

      getVoucherTypeList = async () =>{
          await MoneyLinkService.getVoucherTypeList().then(data => {
            if(data.response_code == 200){
              this.setState({voucherTypeList:data.result.voucher_types})
            }
          })
        }

    componentDidMount() {
      this.getVoucherTypeList();
      this.getLedgerNameList()
      this.getItemLedgers();
      this.getSnapshotMasters();
  
      // shortcut key
  
      Mousetrap.bind("ctrl+shift+up", () => this.scroll("up"));
      Mousetrap.bind("ctrl+shift+down", () => this.scroll("down"));
      Mousetrap.bind("ctrl+shift+left", () => this.scroll("left"));
      Mousetrap.bind("ctrl+shift+right", () => this.scroll("right"));
    }
  
    componentDidUpdate(prevProps, prevState) {
      // shortcut key
      Mousetrap.bind("ctrl+shift+up", () => this.scroll("up"));
      Mousetrap.bind("ctrl+shift+down", () => this.scroll("down"));
      Mousetrap.bind("ctrl+shift+left", () => this.scroll("left"));
      Mousetrap.bind("ctrl+shift+right", () => this.scroll("right"));
    }
  
    componentWillUnmount() {
      Mousetrap.unbind([
        "ctrl+shift+up",
        "ctrl+shift+down",
        "ctrl+shift+left",
        "ctrl+shift+right",
      ]);
    }
  
  
    // validation function for matching pan gst
    validationMatching(data) {
      console.log("validation matching -------------------------", data);
      let errors = {};
  
      data.forEach((row, index) => {
        if (row.disabled) {
          return;
        }
  
        // Check if both PARTY GST and PARTY PAN are not empty
        if (row.gst_no.trim() !== "" && row.pan_number.trim() !== "") {
          // Validate against merchant list for GST/PAN uniqueness but with a different party name
          const duplicate = this.state.all_merchant_list.find(
            (merchant) =>
              merchant.gst_no === row.gst_no &&
              merchant.pan_number === row.pan_number &&
              merchant.label !== row.merchant_name
          );
  
          if (duplicate) {
            errors[index] = {
              ...errors[index],
              gst_no:
                "Merchant with same GST already exists with a different name.",
              pan_number:
                "Merchant with same PAN already exists with a different name.",
            };
          }
        }
  
        // If PARTY GST is not empty but PARTY PAN is, validate only for GST
        else if (row.gst_no.trim() !== "") {
          const duplicateGST = this.state.merchant_list.find(
            (merchant) =>
              merchant.gst_no === row.gst_no &&
              merchant.label !== row.merchant_name
          );
  
          if (duplicateGST) {
            errors[index] = {
              ...errors[index],
              gst_no:
                "Merchant with same GST already exists with a different name.",
            };
          }
        }
  
        // If PARTY PAN is not empty but PARTY GST is, validate only for PAN
        else if (row.pan_number.trim() !== "") {
          const duplicatePAN = this.state.merchant_list.find(
            (merchant) =>
              merchant.pan_number === row.pan_number &&
              merchant.label !== row.merchant_name
          );
  
          if (duplicatePAN) {
            errors[index] = {
              ...errors[index],
              pan_number:
                "Merchant with same PAN already exists with a different name.",
            };
          }
        }
  
        // Other validation rules can be added here
      });
  
      return errors;
    }
  
    //function to validate inventory mode data
    validateInventoryModeData = (data) => {

      let inventory_details = data.inventory_details;
      let rowdata = {
        "type": this.state.type,
        //"voucher_creation_date": inventory_details.voucher_creation_date ?  inventory_details.voucher_creation_date : this.state.date || null ,
        "voucher_creation_date": inventory_details.voucher_creation_date || data.date,
        "sgst_amount": data.sgst_amount ? parseFloat(data.sgst_amount) : null,
        "cgst_amount": data.cgst_amount ? parseFloat(data.cgst_amount) : null,
        "igst_amount": data.igst_amount ? parseFloat(data.igst_amount) : null,
        "is_igst": data.is_igst,
        "party_ledger_guid": inventory_details.party_ledger_guid || null,
        "common_item_ledger_guid": inventory_details.common_item_ledger_guid || null,
        "entry_status": data.entry_status,
        "accounting_status": 3,
        "inventory_voucher_status": inventory_details.inventory_voucher_status,
        "is_common_item_ledger": inventory_details.is_common_item_ledger,
        "line_items": inventory_details.line_items,
        "ledgers": inventory_details.ledgers,
        "accounting_user": this.SNAPSHOT_LEDGER_ENTRIES_CODE,
        "tds_status": data.tds_status,
        "tds_details": data.tds_details,
        "tds_amount": data.tds_amount,
      }
      //console.log(data)
      let errors = validateExcelInventoryModeData(rowdata);
      console.log('ttttttttttttttttt------',errors);

      return errors;
      // if (Object.keys(errors.errors).length == 0 && errors.line_item_errors.length == 0 && errors.ledger_errors.length == 0) {
      //   return true;
      // } else {
      //   toast.error("Wrong input provided")
      //   this.setState({
      //     inventory_errors: { ...errors.errors, inventory_error_status: 'Please fill required mandatory fields.' },
      //     line_item_errors: errors.line_item_errors,
      //     ledger_errors: errors.ledger_errors
      //   })
      //   return false
      // }
    }

    // function for ignor
    ignoreFunction(data) {
      console.log('igonore -------------------', data);
      let errors = {};
      const matchingMerchant = this.state.all_merchant_list.find(
        (merchant) => merchant.label === data.merchant_name
      );
  
      const matchingCategoriesList = this.state.categories.find(
        (category) => category?.id.toString() === data?.category_id.toString()
      );
  
      // Check for matching tags
      const matchingTagsList =
        Array.isArray(data.tags) &&
        data.tags.some((value) => {
          return this.state.tags.some((tag) => tag.label === value);
        });
  
      if (data.disabledIgnore || data.disabled) {
        return;
      }
  
      if (!matchingMerchant && data.merchant_name) {
        errors.ignore_merchant_name =
          "This merchant name is new you can ignore it";
      }
      if ((!matchingCategoriesList && data.category_id != "") || (data.isNewCategory && data.newCategoryName)) {
        console.log("sdasdsadsdsd---sdsdsdsdsd");
        errors.ignore_category_name =
          "This category name is new you can ignore it";
      }
      if (matchingTagsList) {
        errors.ignore_tags_name = "This tags name is new you can ignore it";
      }
  
      return errors;
    }
  
    ignorePartyName = (index) => {
      console.log("newp arty name data ------------------", index, this.state.excelData[index], this.state.all_merchant_list)
      let merchantOpt = this.state.excelData[index].merchant_option
      const newParty = [
        {
          category_id: this.state.excelData[index].category_id, // You need to set this value appropriately
          value: merchantOpt.label,
          label: merchantOpt.label,
          gst_no: this.state.excelData[index].gst_no,
          pan_number: this.state.excelData[index].pan_number,
          tds_percentage: this.state.excelData[index].tds_percentage,
        },
      ];
  
  
      console.log("newp arty name data ------------------", newParty)
  
  
      // update value into duplicate party name
      const updatedNewPartyName = newParty.map((partyValue) => {
        const updatedNewMerchants = this.state.all_merchant_list.find(
          (merchant) => merchant.label === partyValue.label
        );
        const merchantID = updatedNewMerchants
          ? updatedNewMerchants.value
          : partyValue.value;
        return {
          ...partyValue,
          value: merchantID,
        };
      });
  
      console.log("newp arty data -------------", updatedNewPartyName);
  
      // /// if party name new then add in master payload
  
      // const existingMerchatName = this.state.all_merchant_list.find(
      //   (party) => party.value === newParty.value
      // );
      // console.log(
      //   "222 check1:::existingMerchatName:::",
      //   existingMerchatName
  
      // );
  
      // if (
      //   !existingMerchatName &&
      //   !masterPayload.merchants.find(
      //     (merchant) =>
      //       merchant.name === newParty.value
      //   )
      // ) {
      //   console.log(
      //     "3333 check1:::existingMerchatName:::",
      //     existingMerchatName
  
      //   );
      //   const merchant = {
      //     name: newParty.label,
      //     gst_no: this.state.excelData[index].gst_no,
      //     pan_number: this.state.excelData[index].pan_number,
      //     type: 3, //merchant type
      //     is_msme: this.state.excelData[index].is_msme ? 1 : 0,
      //   };
  
      //   masterPayload.merchants.push(merchant);
      // }
  
  
      if (merchantOpt.label !== undefined) {
        this.setState(
          (prevState) => ({
            all_merchant_list: [
              ...prevState.all_merchant_list,
              ...updatedNewPartyName,
            ],
          }),
          () => {
            var opt = this.state.excelData[index].merchant_option;
            opt.value = opt.label;
            const updatedData = this.state.excelData.map((row, ind) =>
              ind === index
                ? {
                  ...row,
                  is_new: false,
                  disabledIgnore: true,
                  merchant_option: opt,
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              // master payload
              // Check if the PARTY NAME is already present in merchant_list state
              const existingMerchatName = this.state.all_merchant_list.find(
                (party) => party.value === this.state.excelData[index].merchant_name
              );
              console.log(
                "222 check1:::existingMerchatName:::",
                existingMerchatName,
                this.state.excelData[index].merchant_name
              );
  
              if (
                existingMerchatName &&
                !masterPayload.merchants.find(
                  (merchant) =>
                    merchant.name === this.state.excelData[index].merchant_name
                )
              ) {
                console.log(
                  "3333 check1:::existingMerchatName:::",
                  existingMerchatName,
                  this.state.excelData[index]
                );
                console.log("type value -------------", this.state.excelData[index].type);
  
                let tp = this.state.excelData[index].type ? this.state.snapshot_types.find((item) => item.id == this.state.excelData[index].type).merchant_type : 3;
                const merchant = {
                  name: this.state.excelData[index].merchant_name,
                  gst_no: this.state.excelData[index].gst_no,
                  pan_number: this.state.excelData[index].pan_number,
                  type: tp ? tp : 3, //merchant type
                  is_msme: this.state.excelData[index].is_msme ? 1 : 0,
                };
                console.log("merchant --- ", merchant, tp);
                masterPayload.merchants.push(merchant);
              }
              //end master payload
  
              if (
                this.state.excelData[index].ignoreErrors?.ignore_category_name
              ) {
                this.ignoreCategory(index);
              } else this.checkForReadyIgnoreMsg(index);
            });
          }
        );
      }
    };
  
    ignoreCategory = (rowIndex) => {
      // this.setState(
      //   (prevState) => ({
      //     categories: [
      //       ...prevState.categories,
      //       {
      //         id: this.state.excelData[rowIndex].newCategoryName,
      //         name: this.state.excelData[rowIndex].newCategoryName,
      //       },
      //     ],
      //   }),
      //   () => {
  
      const newCategory = {
        id: this.state.excelData[rowIndex].newCategoryName,
        name: this.state.excelData[rowIndex].newCategoryName,
      };
  
      const existingCategory = this.state.categories.find(
        (cat) => cat.name === this.state.excelData[rowIndex].newCategoryName
      );
      console.log("exs=isting category ===========", existingCategory,);
      if (
        !existingCategory &&
        !masterPayload.categories.includes(
          this.state.excelData[rowIndex].newCategoryName
        )
      ) {
        // console.log("data.CATEGORY::::data.CATEGORY::", data.CATEGORY);
        masterPayload.categories.push(
          this.state.excelData[rowIndex].newCategoryName
        );
  
        console.log(
          "merchant value category ----------------",
          masterPayload
        );
      }
  
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            isNewCategory: false,
            newCategoryName: "",
            category_id: newCategory.id
          }
          : row
      );
      this.setState(
        {
          excelData: updatedData,
          categories: [...this.state.categories, newCategory],
        },
        () => {
          if (this.state.excelData[rowIndex].ignoreErrors?.ignore_tags_name) {
            this.ignoreTags(rowIndex);
          } else this.checkForReadyIgnoreMsg(rowIndex);
        }
      );
      // }
      // );
    };
  
    ignoreTags = (index) => {
      const updatedData = this.state.excelData.map((row, ind) =>
        ind === index
          ? {
            ...row,
            disabledIgnore: true,
            ignoreErrors: {},
          }
          : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyIgnoreMsg(index);
      });
    };
    handleIgnore = (index) => {
      if (this.state.excelData[index].ignoreErrors?.ignore_merchant_name) {
        this.ignorePartyName(index);
      } else {
        if (this.state.excelData[index].ignoreErrors?.ignore_category_name) {
          this.ignoreCategory(index);
        } else {
          if (this.state.excelData[index].ignoreErrors?.ignore_tags_name) {
            this.ignoreTags(index);
          }
        }
      }
    };
  
    handleIgnoreAll = async () =>{
      const confirmation_result = await confirm({confirmation: `Do you wish to ignore warnings of all snapshots?`});
      if(confirmation_result){
        this.setState({ isSelectFile: true }, () => {
        // Delay execution to allow the loader to render
        // setTimeout(async () => {
        //   await Promise.all(
        //     this.state.excelData.map((data, index) => {
        //       if (data.ignoreErrors?.ignore_merchant_name) {
        //         return this.ignorePartyName(index);
        //       } else if (data.ignoreErrors?.ignore_category_name) {
        //         return this.ignoreCategory(index);
        //       } else if (data.ignoreErrors?.ignore_tags_name) {
        //         return this.ignoreTags(index);
        //       }
        //       return Promise.resolve();
        //     })
        //   );
      
        //   // Hide the loader after the operation is complete
        //   this.setState({ isSelectFile: false });
        // }, 0);
        setTimeout(()=>{
          // let excelDataCP = [...this.state.excelData];
        let all_merchant_listCP = [...this.state.all_merchant_list];
        let categoriesCP = [...this.state.categories];
  
        const updatedData = this.state.excelData.map((row) => {
          let item = { ...row };
          //merchnat
          if (item.ignoreErrors?.ignore_merchant_name) {
            console.log("newp arty name data ------------------", item, all_merchant_listCP)
            let merchantOpt = item.merchant_option
            const newParty = [
              {
                category_id: item.category_id, // You need to set this value appropriately
                value: merchantOpt.label,
                label: merchantOpt.label,
                gst_no: item.gst_no,
                pan_number: item.pan_number,
                tds_percentage: item.tds_percentage,
              },
            ];
            console.log("newp arty name data ------------------", newParty);
  
              // update value into duplicate party name
              const updatedNewPartyName = newParty.map((partyValue) => {
                const updatedNewMerchants = all_merchant_listCP.find(
                  (merchant) => merchant.label === partyValue.label
                );
                const merchantID = updatedNewMerchants  ? updatedNewMerchants.value : partyValue.value;
                return {
                  ...partyValue,
                  value: merchantID,
                };
              });
              console.log("newp arty data -------------", updatedNewPartyName);
  
              if (merchantOpt.label !== undefined) {
                // all_merchant_listCP.push(updatedNewPartyName)
                all_merchant_listCP = [...all_merchant_listCP, ...updatedNewPartyName];
                // this.setState(
                //   (prevState) => ({
                //     all_merchant_list: [
                //       ...prevState.all_merchant_list,
                //       ...updatedNewPartyName,
                //     ],
                //   }),)
  
                var opt = item.merchant_option;
                opt.value = opt.label;
  
                item.is_new= false;
                item.disabledIgnore= true;
                item.merchant_option= opt;
  
                // master payload
                // Check if the PARTY NAME is already present in merchant_list state
                const existingMerchatName = all_merchant_listCP.find((party) => party.value === item.merchant_name);
                if ( existingMerchatName && !masterPayload.merchants.find( (merchant) => merchant.name === item.merchant_name) ) {
                    let tp = item.type ? this.state.snapshot_types.find((_item) => _item.id == item.type).merchant_type : 3;
                    const merchant = {
                      name: item.merchant_name,
                      gst_no: item.gst_no,
                      pan_number: item.pan_number,
                      type: tp ? tp : 3, //merchant type
                      is_msme: item.is_msme ? 1 : 0,
                    };
                    console.log("merchant --- ", merchant, tp);
                    masterPayload.merchants.push(merchant);
                }
                //end master payload
              }
          }
          //category
          if (item.ignoreErrors?.ignore_category_name){
              const newCategory = {
                id: item.newCategoryName,
                name: item.newCategoryName,
              };
  
              const existingCategory = categoriesCP.find((cat) => cat.name === item.newCategoryName );
              console.log("exs=isting category ===========", existingCategory,);
              if (!existingCategory && !masterPayload.categories.includes(item.newCategoryName)) {
                  console.log("data.CATEGORY::::data.CATEGORY::", item);
                  masterPayload.categories.push(item.newCategoryName);
              }
              item.isNewCategory= false;
              item.newCategoryName= "";
              item.category_id= newCategory.id;
              categoriesCP = [...categoriesCP, newCategory];
              // this.setState({categories:[...this.state.categories,newCategory]})
              // categoriesCP.push(newCategory)
          } 
          
          // tags
          if(item.ignoreErrors?.ignore_tags_name){
            item.disabledIgnore= true;
            item.ignoreErrors= {};
          }
          let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(item);
          item.ignoreErrors = ignoreErrors;
  
          return item;
        })
        console.log("updated data",all_merchant_listCP)
        // const updatedMerchantList = [
        //   ...this.state.all_merchant_list,
        //   ...all_merchant_listCP,
        // ];
        // const updatedCategories = [
        //   ...this.state.categories,
        //   ...categoriesCP,
        // ];
        this.setState({excelData:updatedData,all_merchant_list:all_merchant_listCP,categories:categoriesCP,isSelectFile:false,})
        // this.setState({all_merchant_list:all_merchant_listCP},()=>{
        //   this.setState({categories:categoriesCP},()=>{
        //     this.setState({excelData:updatedData,isSelectFile:false})
        //   })
        // })
        },0)
        
      });
  
    }
  
  }
  
    shouldEnableIgnoreButton(records) {
      return records.some(record => {
        return record.ignoreErrors && Object.keys(record.ignoreErrors).length > 0;
      });
    }
  
  
    // shortcut key scroll function
    scroll(direction) {
      const { scrollStep, scrollTop, scrollLeft } = this.state;
      const element = this.scrollRef.current;
  
      let newScrollTop = scrollTop;
      let newScrollLeft = scrollLeft;
      if (direction === "up") {
        newScrollTop = Math.max(scrollTop - scrollStep, 0);
      } else if (direction === "down") {
        newScrollTop = scrollTop + scrollStep;
      } else if (direction === "left") {
        newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
      } else if (direction === "right") {
        newScrollLeft = scrollLeft + scrollStep;
      }
  
      // Update state and scroll position
      this.setState(
        { scrollTop: newScrollTop, scrollLeft: newScrollLeft },
        () => {
          if (element) {
            element.scrollTop = newScrollTop;
            element.scrollLeft = newScrollLeft;
          }
        }
      );
    }
    // add new category function
    handleNewCategorySubmit = (e, rowIndex) => {
      if (e.key === "Enter") {
        const newCategory = {
          id: this.state.excelData[rowIndex].newCategoryName,
          name: this.state.excelData[rowIndex].newCategoryName,
        };
  
        console.log("categories ------------", this.state.categories);
        const existingCategory = this.state.categories.find(
          (cat) => cat.name === newCategory.name
        );
        console.log("exs=isting category ===========", existingCategory);
        if (
          !existingCategory &&
          !masterPayload.categories.includes(
            newCategory.name
          )
        ) {
          // console.log("data.CATEGORY::::data.CATEGORY::", data.CATEGORY);
          masterPayload.categories.push(
            newCategory.name
          );
  
          console.log(
            "merchant value category ----------------",
            masterPayload
          );
        }
  
  
  
        // Update the excelData with the new party name
        const newData = this.state.excelData.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              newCategoryName: "",
              isNewCategory: false,
              category_id: newCategory.id, // Assuming 'PARTY NAME' is the key for party name in excelData
            };
          } else {
            return row;
          }
        });
  
        this.setState({
          excelData: newData,
          categories: [...this.state.categories, newCategory],
        });
      }
    };
  
    handleCategoryChange = (e, rowIndex) => {
      const { value } = e.target;
      const { checked } = e.target;
      console.log("value ===================== before ", value);
      // add new category , check if user is adding new category
      if (value === "-2") {
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex
            ? {
              ...row,
              isNewCategory: true,
              newCategoryName: "",
              editableRowIndex: rowIndex,
            }
            : row
        );
        this.setState({ excelData: updatedData }, () => {
          this.checkForReadyState(rowIndex);
        });
  
        return;
      }
      console.log("value ===================== after ");
  
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, category_id: value } : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyState(rowIndex);
      });
    };
    getSnapshotMasters = () => {
      SnapshotService.getSnapshotMasters().then((data) => {
        if (data.response_code == 200) {
          let merchants = data.result.merchants.map((supp) => {
            return {
              value: supp.id,
              label: supp.name,
              gst_no: supp.gst_no || "",
              pan_number: supp.pan_number || "",
              tds_percentage: supp.tds_percentage,
              snapshot_type: supp.snapshot_type,
              is_gst_claim_eligible: supp.is_gst_claim_eligible,
              is_msme: supp.is_msme,
              is_perquisite: supp.is_perquisite,
              type: supp.type,
              category_id: supp.category_id,
              tags: supp.tags || [],
              party_ledger_guid: supp.party_ledger_guid || "",
              purchase_ledger_guid: supp.purchase_ledger_guid || "",
              sales_ledger_guid: supp.sales_ledger_guid || "",
              purchase_voucher_type_guid : supp.purchase_voucher_type_guid || "",
              sales_voucher_type_guid : supp.sales_voucher_type_guid || ""

            };
          });
  
          let tagData = data.result.tags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }));
          console.log("PArty Details List ----", data.result);
          this.setState({
            // payment_details: data.result.financial_institutes,
            categories: data.result.categories,
            tags: tagData,
            all_merchant_list: merchants,
            all_old_merchant_list: _.cloneDeep(merchants),
            snapshot_types: data.result.snapshot_types,
            line_items_list: data.result.line_items
          });
        }
      });
    };
  
    toggleImportExcelPopup = () => {
      this.props.toggleImportExcelPopup(this.state.excelData);
      this.setState({ excelData: [], isDownload: false, isReady: false });
      //this.getSnapshotMasters();
    };
    handleSupplierCustomerToggle = (name, value, rowIndex) => {
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            [name]: value,
          }
          : row
      );
      this.setState({ excelData: updatedData }, () => {
        let merchant_list = this.state.all_merchant_list.filter(
          (merchant, index) => {
            if (
              merchant.type == 3 ||
              (this.state.is_customer && merchant.type == 1) ||
              (this.state.is_supplier && merchant.type == 2) ||
              (!this.state.is_customer && !this.state.is_supplier)
            ) {
              return merchant;
            }
          }
        );
  
        this.setState({ merchants: merchant_list });
      });
    };
    handleStarClick = (rowIndex) => {
      // Toggle star clicked status
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            star: row.star.toUpperCase() === "YES" ? "No" : "Yes",
            // merchant_name: "testing data",
          }
          : row
      );
      this.setState({ excelData: updatedData }, () => {
        console.log("updated data ---------", this.state.excelData);
      });
    };
    saveLineItemData = (data,rowIndex) =>{
      console.log("data:::::::::::",data,rowIndex)

          let inventory_details = {
            "line_item_total": data.line_item_total,
            "line_item_gst_total": data.line_item_gst_total,
            "line_item_discount_total": data.line_item_discount_total,
            "inventory_voucher_status": data.inventory_voucher_status,
            "is_common_item_ledger": data.is_common_item_ledger,
            "accounting_narration": data.accounting_narration,
            "line_items": data.line_items,
            "ledgers": data.ledgers,
            "sync_datetime": data.sync_datetime,
            "sync_error": data.sync_error,
            "sync_status": data.sync_status,
            "voucher_total_amount": data.voucher_total_amount,
            "voucher_creation_date": data.voucher_creation_date,
            "party_ledger_guid": data.party_ledger_guid,
            "party_ledger_id": data.party_ledger_id,
            "party_ledger_name": data.party_ledger_name,
            "common_item_ledger_guid": data.common_item_ledger_guid,
            "common_item_ledger_id": data.common_item_ledger_id,
            "common_item_ledger_name": data.common_item_ledger_name,
            "party_ledger_guid_prediction_status": data.party_ledger_guid_prediction_status,
            "party_ledger_id_prediction_status":data.party_ledger_id_prediction_status,
            "party_ledger_name_prediction_status":data.party_ledger_id_prediction_status,
            "common_item_ledger_guid_prediction_status":data.common_item_ledger_guid_prediction_status,
            "common_item_ledger_id_prediction_status":data.common_item_ledger_id_prediction_status,
            "common_item_ledger_name_prediction_status":data.common_item_ledger_name_prediction_status,
            "voucher_creation_date_prediction_status":data.voucher_creation_date_prediction_status,
            "parent_voucher_type_id":data.parent_voucher_type_id,
            "voucher_type_guid":data.voucher_type_guid,
            "voucher_type_name":data.voucher_type_name,
            "voucher_type_id":data.voucher_type_id,
            // "gst_summary": data.gst_summary,
            // "hsn_sac_summar
        }

        // if(!data.total_amount)
        //   this.setState({total_amount:data.line_item_total});
        console.log("line_item_total ----------------" , data.line_item_total);
        if (data.inventory_voucher_id) {
          inventory_details.inventory_voucher_id = data.inventory_voucher_id;
        }
        inventory_details.remove_line_items = data.remove_line_items || [];
		    inventory_details.remove_ledgers = data.remove_ledgers || [];

        // const updatedData = this.state.excelData.map((row, index) =>
        //   index === rowIndex ? { ...row, inventory_details: inventory_details, is_dirty: true } : row
        // );

        /*check total amount based on line item */
        const amount = parseFloat(data.line_item_total || 0) + parseFloat(data.line_item_gst_total || 0);
        console.log(amount)
        // const calculated_amount = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,amount)) : parseFloat(amount.toFixed(2));
        const calculated_amount = this.props.amount_round_off ?roundOffValue(this.props.amount_round_off,amount) : amount.toFixed(2);
        /*check total amount based on line item */

        /* check gst */
        const excelData = [...this.state.excelData];
        let updatedRow = {...excelData[rowIndex]}
        console.log(updatedRow);
        let cgst = null;
        let sgst = null;
        let igst = null;
        console.log("check type",parseFloat(data.line_item_gst_total) == 0)
        let gst_line_item = parseFloat(data.line_item_total) == 0 ? "" : parseFloat(data.line_item_gst_total);
        if(updatedRow.is_igst == 0){
          // cgst = data.line_item_gst_total/2;
          cgst = gst_line_item / 2
         //  cgst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,cgst)) : parseFloat(cgst.toFixed(2));
         cgst = cgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,cgst) : cgst.toFixed(2) : "";
          // sgst = data.line_item_gst_total/2;
          sgst = gst_line_item / 2
         //  sgst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,sgst)) : parseFloat(sgst.toFixed(2));
         sgst = sgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,sgst) : sgst.toFixed(2) : "";
       }
       if(updatedRow.is_igst == 1){
         //  igst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,data.line_item_gst_total)) : parseFloat(data.line_item_gst_total.toFixed(2));
         igst = gst_line_item ? this.props.amount_round_off ?roundOffValue(this.props.amount_round_off,gst_line_item) : gst_line_item : "";
       }
        // if(updatedRow.cgst_amount && updatedRow.sgst_amount){
        //    cgst = parseFloat(data.line_item_gst_total/2);
        //   //  cgst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,cgst)) : parseFloat(cgst.toFixed(2));
        //   cgst = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,cgst) : cgst.toFixed(2);
        //    sgst = parseFloat(data.line_item_gst_total/2);
        //   //  sgst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,sgst)) : parseFloat(sgst.toFixed(2));
        //   sgst = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,sgst) : sgst.toFixed(2);
        // }
        // if(updatedRow.igst_amount){
        //   //  igst = this.props.amount_round_off ? parseFloat(roundOffValue(this.props.amount_round_off,data.line_item_gst_total)) : parseFloat(data.line_item_gst_total.toFixed(2));
        //   igst = this.props.amount_round_off ?roundOffValue(this.props.amount_round_off,data.line_item_gst_total) : data.line_item_gst_total.toFixed(2);
        // }
        /* check gst */

        /*update total gst */
          let gst_total = parseFloat(sgst || 0) +
          parseFloat(cgst|| 0) +
          parseFloat(igst || 0);
        /*update total gst */

        /*update tds*/
            let total_amt =
            parseFloat(calculated_amount || 0) -
            parseFloat(gst_total || 0);
          let tds_amount =
            (total_amt *
              parseFloat(this.state.excelData[rowIndex].tds_percentage || 0)) /
            100;
      
          console.log("tds amount value -------------", tds_amount);
          if (tds_amount < 0) {
            tds_amount = 0;}
          tds_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds_amount) : parseFloat(tds_amount).toFixed(2)
        /*update tds*/
        
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex ? { ...row, total_amount:calculated_amount,cgst_amount : cgst , sgst_amount: sgst , igst_amount : igst , gst_total_amount:gst_total,
            tds_amount:tds_amount,
          inventory_details: inventory_details, is_dirty: true ,} : row
        );
        this.setState({ excelData: updatedData }, () => {
          this.checkForReadyState(rowIndex)
          // Added code to update error state while come back from inventory popup
          setTimeout(async()=>{
            await this.checkForDraftToReadyState();
            this.setState({isSelectFile:false})
          },0)
          // if(this.state.isReady){
          //   setTimeout(async()=>{
          //     await this.checkForDraftToReadyState();
          //     this.setState({isSelectFile:false})
          //   },0)
          // }else{
          //   setTimeout(()=>{
          //     const updatedData = this.state.excelData.map((row, index) =>{
          //       let updatedRow = {...row}
          //       updatedRow.entry_status = 1;
          //       updatedRow.is_dirty = true;
          //       return updatedRow
          //     }
               
          //     );
          //     this.setState({ excelData: updatedData ,isSelectFile:false}, () => {})
          //   },0)
            
          // }
        });

  }
    findPartyInventory = (row) => {
      console.log("row ------------", row)
      var partyName = row.party;
      var gstNo = row.gst ? row.gst.toString() : "";
      var defaultData = {
        category_id: "",
        gst_no: row.gst ? row.gst.toString() : "",
        value: 0,
        label: row.party ? row.party.toString() : "",
        pan_number: (checkValidGSTNo(gstNo)?gstNo.substring(2, 12):""),
        tds_percentage: "",
        snapshot_type: false,
        is_msme: false,
        type: "",
        tags: [],
        party_ledger_guid: "",
        purchase_ledger_guid: "",
        sales_ledger_guid: "",
        purchase_voucher_type_guid:"",
        sales_voucher_type_guid:""
  
      };
     
      var record = defaultData;
      if (gstNo) {
        console.log("Compare with GST First");
        record = this.state.all_merchant_list.find(
          (record) => record.gst_no.toUpperCase() === gstNo.toUpperCase()
        );
      }
      console.log("compare",record,partyName)
      if ((record == undefined)&& partyName) {
        console.log("Compare with party name at last");
        record = this.state.all_merchant_list.find(
          (record) => record.label.toUpperCase() === partyName.toUpperCase()
        );
      }
      if (!record) record = defaultData;
      console.log("record data ---------- ", record);
      return record;
    }
    findPartyDetails = (row) => {
      console.log("row ------------", row);
      var partyName = row[1];
      var gstNo = row[2] ? row[2].toString() : "";
      var panNo = row[3] ? row[3].toString() : "";
      var defaultData = {
        category_id: "",
        gst_no: row[2] ? row[2].toString() : "",
        value: 0,
        label: row[1] ? row[1].toString() : "",
        pan_number: row[3] ? row[3].toString() : "",
        tds_percentage: "",
        snapshot_type: false,
        is_msme: false,
        type: "",
        tags: [],
        party_ledger_guid: "",
        purchase_ledger_guid: "",
        sales_ledger_guid: "",
      };
      var record = defaultData;
      if (gstNo) {
        console.log("Compare with GST First");
        record = this.state.all_merchant_list.find(
          (record) => record.gst_no.toUpperCase() === gstNo.toUpperCase()
        );
      }
      if (record && record.value == 0 && panNo) {
        console.log("Compare with Pan number secondly");
        record = this.state.all_merchant_list.find(
          (record) => record.pan_number.toUpperCase() === panNo.toUpperCase()
        );
      }
      if (record && record.value == 0 && partyName) {
        console.log("Compare with party name at last");
        record = this.state.all_merchant_list.find(
          (record) => record.label.toUpperCase() === partyName.toUpperCase()
        );
      }
      if (!record) record = defaultData;
      console.log("record data ---------- ", record);
      return record;
    };
  
    formatDate(dateString) {
      // Helper function to get the current date formatted as "dd-mm-yyyy"
      // Helper function to get the current date formatted as "dd-mm-yyyy"
      const getCurrentDateFormatted = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        return `${day}-${month}-${year}`;
      };
  
      // Return current date if input is empty
      if (!dateString) {
        return getCurrentDateFormatted();
      }
  
      // Convert dateString to a string if it's not already
      // let dateStringStr = dateString.toString();
      let dateStringStr = dateString.toString().trim();
  
      if (
        !isNaN(dateStringStr) &&
        dateStringStr.indexOf("-") === -1 &&
        dateStringStr.indexOf("/") === -1
      ) {
        const MS_PER_DAY = 86400000; // Number of milliseconds in a day
        const EPOCH_OFFSET_DAYS = 25569; // Excel epoch starts on December 30, 1899
        const excelDateInMS =
          (parseInt(dateStringStr) - EPOCH_OFFSET_DAYS) * MS_PER_DAY;
        const date = new Date(excelDateInMS);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
  
      // Splitting the input string by dashes or slashes
      let parts = dateStringStr.split(/-|\//);
  
      let day, month, year;
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          // Likely format is yyyy-mm-dd or yyyy/mm/dd
          year = parts[0];
          month = parts[1];
          day = parts[2];
        } else if (parts[2].length === 4) {
          // Likely format is dd-mm-yyyy or mm/dd/yyyy
          year = parts[2];
          month = parts[1];
          day = parts[0];
        } else {
          // If none of the expected formats match, return the current date
          return getCurrentDateFormatted();
        }
  
        // Pad single digits with leading zeros
        day = day.padStart(2, "0");
        month = month.padStart(2, "0");
  
        // Validate date to prevent returning an invalid date format
        const validationDate = new Date(year, month - 1, day);
        if (
          validationDate.getFullYear() !== parseInt(year) ||
          validationDate.getMonth() + 1 !== parseInt(month) ||
          validationDate.getDate() !== parseInt(day)
        ) {
          return getCurrentDateFormatted();
        }
  
        return `${day}-${month}-${year}`;
      } else {
        // If parts length is not 3, indicating an incorrect format, return current date
        return getCurrentDateFormatted();
      }
    }
  
    handleRestore = (rowIndex) => {
      const updatedData = this.state.excelData.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow = {
            ...row,
          };
  
          updatedRow.disabled = false;
          let data = {
            isNewCategory: updatedRow.isNewCategory,
            newCategoryName: updatedRow.newCategoryName,
            category_id: updatedRow.category_id ? updatedRow.category_id : "",
            tags: updatedRow.tags,
            merchant_name: updatedRow.merchant_option.label || null,
            disabledIgnore: updatedRow.disabledIgnore,
          };
          //console.log("data --------" ,Number.isInteger(dataObj.category_id), data);
          updatedRow.ignoreErrors = this.ignoreFunction(data);
  
          return updatedRow;
        } else {
          return row;
        }
      });
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyState(rowIndex);
      });
    };
  
    handleDisable = (rowIndex) => {
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, disabled: true, ignoreErrors: {}, errors: {} } : row
      );
      this.setState({ excelData: updatedData }, () => {
        //this.checkForReadyState(rowIndex);
      });
    };
  
    handleDrop = (acceptedFiles) => {
      if (acceptedFiles.target.files.length == 0) return;
      this.setState(
        {
          excelData: [],
          isUploading: false,
          isDownload: false,
          isReady: false,
          uploading_file_percent: 0,
        },
        () => {
          masterPayload.tags = [];
          masterPayload.categories = [];
          masterPayload.merchants = [];
  
          const file = acceptedFiles.target.files[0];
          console.log("file:::", file.name);
  
          console.log("snapshot types ----------------", this.state.snapshot_types);
          this.setState({ fileName: file.name, selectFileError: "" });
          if (file) {
            this.setState({ isSelectFile: true });
            ExcelRenderer(file, (err, resp) => {
              if (err) {
                this.setState({
                  isSelectFile: false,
                });
                console.log("error::", err);
              } else {
                console.log("resonse ----------", resp);
                let keys = resp.rows[0];
                const headersMatch = tableH.every(
                  (item, index) => {
                    console.log("item ---------",item.toUpperCase().trim(),keys[index].toUpperCase().trim(), item, keys[index], item === keys[index]);
                    if (index == 3)
                      return item === "BILL DATE" || item === "BILL DATE(DD-MM-YYYY)"
                    else
                      return item.toUpperCase().trim() === keys[index].toUpperCase().trim()
                  }
                );
                if (!headersMatch) {
                  // Headers do not match, handle error state
                  console.error("Selected Excel File Header mismatch error");
                  this.setState({
                    isSelectFile: false,
                    isDownload: false,
                    selectFileError: "Selected file is not in required format",
                    excelData: [],
                  });
                  return;
                }

                let excelRows = resp.rows.slice(1);
  
                excelRows = excelRows.filter(row => 
                  row.some(cell => cell !== null && cell !== undefined && cell !== "")
                );
                console.log("records ------", excelRows);
                if (excelRows.length > 0 && excelRows.length <= RECORD_LIMIT) {
                  var arr = [];
  
                  let is_reimbursement = false; //(this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? true : false;
  
                  const filteredRows = excelRows.filter(row => 
                    row.some(cell => cell !== null && cell !== undefined && cell !== "")
                  );
                  
                  console.log("records after filtering ------", filteredRows);

                  let billData = {}; // Object to group data
                  excelRows.map((row) => {

                    let voucher_type = row[1] || "";
                    let bill_number = row[2]; // Bill Number
                    let date = this.formatDate(row[3]) || ""; // Date
                    let party = row[4] || ""; // Party   
                    let gst = row[5] || ""  ; //gst
                    let state = row[6] || ""

                    let location = row[7] || ""
                    let item_name = row[8]; // Item Name
                    let hsn = row[9];
                    let gst_rate = row[10];
                  


                    let quantity = row[11]; // Quantity
                    let rate = row[12];
                    let per = row[13];
                    let amount = row[14];
                    let cgst = row[15];
                    let sgst = row[16];
                    let igst  = row[17]
                    let total_value = row[18]

                    if (!billData[bill_number]) {
                      billData[bill_number] = {
                        state,
                        voucher_type,
                        bill_number,
                        date,
                        party,
                        gst,
                        line_items: [],
                      };
                  }

                    billData[bill_number].line_items.push({
                      item_name,hsn,gst_rate,rate,per,
                      quantity,location,amount,cgst,sgst,igst,total_value
                   });

                  })  

                  const structuredData = Object.values(billData);
                  console.log("structured array",structuredData)


                  structuredData.forEach((row)=> {

                    /* Party Started */
                    var record = this.findPartyInventory(row);
                      console.log(
                        "Sub Details row Value --- ",
                        row,
                        "merchant --- ",
                        record
                      );
                    /* Party Ended */

                      
                      /* Tag Started */
                      console.log("1-- ", record.tags);
                      const prefillTags = record.tags
                        ? record.tags.map((tag) => {
                          const foundTag = this.state.tags.find(
                            (t) => t.value === tag.id
                          );
                          return foundTag ? foundTag.value : tag.id;
                        })
                        : [];
                        console.log('prefill tags',prefillTags)
                      /* Tag Ended */

                       /* Perquisite Started */
                      const isPerquiset = record.isPerquiset ? record.isPerquiset : false;
                      /* Perquisite Ended */

                     /* msme Started */
                      const isMsme = record.is_msme ? record.is_msme : false
                      /* msme Started */
                      
                      /* gsteligible Started */
                      const isGstEligible = record.is_gst_claim_eligible ? record.is_gst_claim_eligible : false
                      /* gsteligible Ended */
                      
                      /* category Started */
                      const category = record.category_id ? record.category_id : ""
                       /* category ended */

                       /*snapshot type started*/
                       const tp = row.voucher_type || "";

                       const typee = (tp.trim().toUpperCase() == "SALES") ? 1 : (tp.trim().toUpperCase() == "PURCHASE") ? 2 : 8;
                       const snapID = this.state.snapshot_types.find((type) => type.id == typee);
                       const user_snapId = this.state.snapshot_types.find((type) => type.id == record.snapshot_type);

                       const snapshotType = record.value != 0 ? user_snapId ? user_snapId.id : 8 : snapID ? snapID.id : 8
                       const exp = record.value != 0 ? user_snapId ? user_snapId.expense : 1 : snapID ? snapID.expense : 1
                      /*snapshot type ended*/


                      var rowData = {
                        category_id: category ? category : "",
                        // isNewCategory:row[4]?false:true,// updatedNewcat ?false : true,
                        isNewCategory: false,
                        newCategoryName: "",
                        additional_information: "",
                        star: "NO",
                        is_edit_supplier: false,
                        is_new:
                          record.value == 0 && record.label != "" ? true : false,
                        merchant_id: record.value,
                        new_value: record.label,
                        merchant_name: record.label,
                        merchant_option: record,
                        merchant_type: record.type ? record.type : 2,
                        gst_no: record.gst_no.toUpperCase(),
                        pan_number: record.pan_number.toUpperCase(),
                        is_msme: isMsme,
                        is_dirty: false,
                        is_customer: true,
                        is_supplier: true,
                        is_perquisite: isPerquiset,
                        expense: exp,//snapId ? snapId.expense : 0,
                        type: snapshotType,
                        selected_tags: [],
                        // cgst_amount: cgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[13]) : row[13] : "",
                        // sgst_amount: sgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[14]) : row[14] : "",
                        // igst_amount: igst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[15]) : row[15] : "",
                        gst_total_amount: "",
                        // is_igst: igst ? true : false, // igst ? true : false,
                        // disable_igst: igst ? false : true,
                        // total_amount: row[8] ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,row[8]) : row[8] : row[8],
                        tds_percentage: record.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,record.tds_percentage) : record.tds_percentage : "",
                        tds_amount: "",
    
                        is_reimbursement: is_reimbursement,
                        tds_status: false,
                        is_gst_claim_eligible: isGstEligible,
                        gst_status: true, // gstStatus,
                        payment_status: false,
                        date: row.date,
                        invoice_number: row.bill_number ? row.bill_number.toString() : "",
                        party_ledger_guid: "",
                        entry_status: this.state.isReady ? 2 : 1,
                        errors: {},
                        ignoreErrors: {},
                        tags: prefillTags,
                        inventory_mode: false,
                        gst_override:false,
                        hide_gst_override: true,
                      };

                      /* manage inventory mode */
                      if(rowData.type == 1 || rowData.type == 2){
                        rowData.inventory_mode = true;
                      }
                      /* manage inventory mode */

                      /* calulate cgst start*/
                      let cgst = this.calculateTotalInventoryGst(row.line_items,'cgst');
                      rowData.cgst_amount = cgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,cgst) : parseFloat(cgst) : "";
                      /* calulate cgst end*/

                       /* calulate sgst start*/
                       let sgst = this.calculateTotalInventoryGst(row.line_items,'sgst');
                       rowData.sgst_amount = sgst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,sgst) : parseFloat(sgst) : "";
                       /* calulate sgst end*/

                         /* calulate igst start*/
                         let igst = this.calculateTotalInventoryGst(row.line_items,'igst');
                         rowData.igst_amount = igst ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,igst) : parseFloat(igst) : "";
                         console.log("rowdata",typeof(rowData.igst_amount))
                         /* calulate igst end*/

                         /* is_igst and disable igst start */
                          rowData.is_igst = rowData.igst_amount ? true : false; // igst ? true : false,
                          rowData.disable_igst =  rowData.igst_amount ? false : true;
                          /* is_igst and disable igst end */

                         /* calulate total start*/
                         let total = this.calculateTotalInventoryGst(row.line_items,'total');
                         rowData.total_amount = total ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,total) : parseFloat(total) : "";
                         /* calulate total end*/

                         /* gst total amount start */
                         let gst_total_amount =  parseFloat(rowData.sgst_amount || 0) +  parseFloat(rowData.cgst_amount || 0) +  parseFloat(rowData.igst_amount || 0);
                         rowData.gst_total_amount = gst_total_amount;
                         /* gst total amound end */

                         /* Tds Percentage start */
                          rowData.tds_percentage = parseInt(rowData.tds_percentage) ? rowData.tds_percentage : "";
                          rowData.tds_percentage = (rowData.type == 9 || rowData.type == 10 || rowData.type == 4 || rowData.type == 5) ? "" : rowData.tds_percentage;
                          /* Tds Percentage end */
                         
                          /* perquisite start */
                          rowData.is_perquisite = (rowData.type == 11 || rowData.type == 7 || rowData.type == 10 || rowData.type == 1 || rowData.type == 9) ? false : true;
                          /* perquisite end */
                         
                          /* Tds amount start */
                           let total_amt = parseFloat(rowData.total_amount || 0) - parseFloat(rowData.gst_total_amount || 0);
                           let tds_amount = (total_amt * parseFloat(rowData.tds_percentage || 0)) / 100;
                           console.log("tds amount value -------------", tds_amount);
                           if (tds_amount < 0) tds_amount = 0;
                                            // rowData.tds_amount = tds_amount.toFixed(2);
                           rowData.tds_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds_amount) :  tds_amount.toFixed(2);
                          /* Tds amount end */
                           
                        /* gst enable and disable along with gst override start*/
                          let customer_gst = rowData.gst_no
                          ? rowData.gst_no.slice(0, 2)
                          : null || null;
                        let entity_gst = !!this.props.entity_gst_no
                          ? this.props.entity_gst_no.slice(0, 2)
                          : null;
                        console.log(
                          "custome gst --------------------------- ",
                          customer_gst,
                          entity_gst
                        );
                        // if (!customer_gst || !entity_gst) {
                        //   rowData.is_igst = false;
                        //   // rowData.igst_amount = "";
                        //   rowData.disable_igst = false;
                        //   rowData.hide_gst_override = true;
                        // } else {
                        //     if (
                        //       customer_gst == entity_gst &&
                        //       customer_gst &&
                        //       entity_gst
                        //     ) {
                        //       rowData.is_igst = false;
                        //       //  rowData.igst_amount = "";
                        //       rowData.disable_igst = true;
                        //       rowData.hide_gst_override = false;
                        //       //    this.calculateTotalGst();
                        //     } else {
                        //       // rowData.is_igst = true;
                        //       rowData.disable_igst = false;
                        //       //  rowData.sgst_amount = "";
                        //       //    rowData.cgst_amount = "";
                        //       rowData.hide_gst_override = true;
                        //     }
                        // }
                        if (!customer_gst || !entity_gst) {
                          // rowData.is_igst = false;
                          // // rowData.igst_amount = "";
                          // rowData.disable_igst = false;
                          // rowData.hide_gst_override = true;
                          if(rowData.cgst_amount && rowData.sgst_amount){
                             rowData.is_igst = false;
                            // rowData.igst_amount = "";
                            rowData.disable_igst = false;
                            rowData.hide_gst_override = true;
                          }else if(rowData.igst_amount){
                            rowData.is_igst = true;
                            // rowData.igst_amount = "";
                            rowData.disable_igst = false;
                            rowData.hide_gst_override = true;
                          }else{
                            console.log("here in false")
                            rowData.is_igst = true;
                            // rowData.igst_amount = "";
                            rowData.disable_igst = false;
                            rowData.hide_gst_override = true;
                          }
                        } else {
                          if (
                            customer_gst == entity_gst &&
                            customer_gst &&
                            entity_gst
                          ) {
                            // rowData.is_igst = false;
                            // //  rowData.igst_amount = "";
                            // rowData.disable_igst = true;
                            // rowData.hide_gst_override = false;
                            if(rowData.cgst_amount && rowData.sgst_amount){
                              console.log("gst same cgst")
                              rowData.is_igst = false;
                               rowData.igst_amount = "";
                              rowData.disable_igst = true;
                              rowData.hide_gst_override = false;
                            }else if(rowData.igst_amount){
                              rowData.is_igst = true;
                              rowData.disable_igst = false;
                              rowData.hide_gst_override = false;
                              rowData.gst_override = true;
                              rowData.sgst_amount = ""
                              rowData.cgst_amount = ""
                            }else{
                              rowData.is_igst = false;
                              //  rowData.igst_amount = "";
                              rowData.disable_igst = true;
                              rowData.hide_gst_override = false;
                            }
                            //    this.calculateTotalGst();
                          }else if(customer_gst != entity_gst && customer_gst && entity_gst){
                            // rowData.is_igst = true;
                            // //  rowData.igst_amount = "";
                            // rowData.disable_igst = true;
                            // rowData.hide_gst_override = false;
                            if(rowData.cgst_amount && rowData.sgst_amount){
                              console.log("gst different cgs")
                              rowData.is_igst = false;
                              rowData.disable_igst = false;
                              rowData.hide_gst_override = false;
                              rowData.gst_override = true;
                              rowData.igst_amount = ""
                            }else if(rowData.igst_amount){
                              rowData.is_igst = true;
                              rowData.disable_igst = true;
                              rowData.hide_gst_override = false;
                              rowData.cgst_amount = ""
                              rowData.sgst_amount = ""
                            }else{
                              rowData.is_igst = true;
                              rowData.disable_igst = true;
                              rowData.hide_gst_override = false;
                            }
                          } else {
                            // rowData.is_igst = true;
                            rowData.disable_igst = false;
                            //  rowData.sgst_amount = "";
                            //    rowData.cgst_amount = "";
                            rowData.is_igst = fasle;
                            rowData.hide_gst_override = true;
                          }
                        }

                       /* gst enable and disable along with gst override end*/

                       /* manage inventory details started*/
                        let inventory_details = this.handleInventory(row.line_items , rowData);
                        rowData.inventory_details = inventory_details;
                      /* manage inventory details end*/
                      /* Narration Start */
                        rowData.narration = this.getNarration(rowData);
                      /*Narration End */
                      let dataObj = rowData;

                      /*ignore function start */
                      let data = {
                        isNewCategory: dataObj.isNewCategory,
                        newCategoryName: dataObj.newCategoryName,
                        category_id: dataObj.category_id ? dataObj.category_id : "",
                        tags: dataObj.tags,
                        merchant_name: dataObj.merchant_option.label || null,
                        disabledIgnore: dataObj.disabledIgnore,
                      };
                      //console.log("data --------" ,Number.isInteger(dataObj.category_id), data);
                      rowData.ignoreErrors = this.ignoreFunction(data);
                      /*ignore function end */

                      /* manage mandatory record data start */
                      let errors =  this.checkForReadyStateOnDrop(rowData)
                      if(!rowData.is_reimbursement){
                        if(Object.keys(errors).length === 0){
                          rowData.errors = {};
                          // rowData.entry_status = 2;
                          rowData.is_dirty = true
                        }else{
                          rowData.errors = errors
                        }
                      }

                     let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(rowData)
                     rowData.ignoreErrors = ignoreErrors;
                     /* manage mandatory record data end */
                      console.log("rowdata prefill",rowData ,cgst,sgst,igst,total)

                      /* manage inventory errors start */
                      let inventory_error = this.validateInventoryModeData(rowData);
                      if (Object.keys(inventory_error.errors).length == 0 && inventory_error.line_item_errors.length == 0 && inventory_error.ledger_errors.length == 0) {
                            // return true;
                            rowData.inventory_errors = {};
                            rowData.line_item_errors = [];
                            rowData.ledger_errors = []
                          } else {
                            // toast.error("Wrong input provided")
                            // this.setState({
                            //   inventory_errors: { ...errors.errors, inventory_error_status: 'Please fill required mandatory fields.' },
                            //   line_item_errors: errors.line_item_errors,
                            //   ledger_errors: errors.ledger_errors
                            // })
                            // return false
                            rowData.inventory_errors = {...inventory_error.errors , inventory_error_status: 'Please fill required mandatory fields.'}
                            rowData.line_item_errors = inventory_error.line_item_errors
                            rowData.ledger_errors = inventory_error.ledger_errors
                          }
                       /* manage inventory errors end */
      
                      /*pushed rowdata into array */
                        arr.push(rowData);
                      /*pushed rowdata into array */
                  })
  
                  this.setState({ excelData: arr ,is_dirty:true}, () => {
                    console.log("excelData",this.state.excelData)
                    this.state.excelData.map((row, rowIndex) => {
                      // this.checkForReadyIgnoreMsg(rowIndex);
                      // this.checkForReadyState(0);
                    });
                  });
                }
                else{
                  this.setState({
                    isSelectFile: false,
                    isDownload: false,
                    selectFileError: "The File must have at least " + RECORD_LIMIT + "  or fewer records.",
                    excelData: [],
                  });
                  return;
                }
                setTimeout(() => {
                  this.setState({
                    isSelectFile: false,
                  });
                }, 2000);
              }
            });
          } else {
            this.setState({
              isSelectFile: false,
              isDownload: false,
            });
          }
        }
      );
    };

    calculateTotalInventoryGst = (line_item,value) => {
      let line_items = [...line_item];
      let amt = 0;
    
      if(value == 'cgst'){
        line_items.forEach((item, index) => {
          if (item.cgst) amt += parseFloat(item.cgst || 0);
        });
        console.log("cgst",amt)
      }

     
    
      if(value == 'sgst'){
        line_items.forEach((item, index) => {
          if (item.sgst) amt += parseFloat(item.sgst || 0);
        });
        console.log("sgst",amt)
      }
     

      if(value == 'igst'){
        line_items.forEach((item, index) => {
          if (item.igst) amt += parseFloat(item.igst || 0);
        });
        console.log("igst",amt)
      }
      if(value == 'total'){
        line_items.forEach((item, index) => {
          if (item.total_value) amt += parseFloat(item.total_value || 0);
        });
        console.log("total",amt)
      }
     
      //this.setState({line_item_total: amt.toFixed(2)})
      return amt.toFixed(2);
    }

     convertToFullDate = (dateString) =>{
      // If no date is provided, use today's date
      if (!dateString) {
        const today = new Date();
        return today.toString(); // Outputs current date in local timezone
      }
    
      // Split the date string (supports '1-2-2025' format)
      const parts = dateString.split("-");
      if (parts.length !== 3) return "Invalid date";
    
      // Parse day, month, and year (auto-handle missing leading zeros)
      const [day, month, year] = parts.map(Number);
    
      // Validate date components
      if (
        isNaN(day) || isNaN(month) || isNaN(year) ||
        day < 1 || day > 31 || month < 1 || month > 12 || year < 1000
      ) {
        return "Invalid date";
      }
    
      // Create a Date object (month is 0-indexed in JavaScript)
      const dateObject = new Date(year, month - 1, day);
    
      // Ensure the Date object matches input values (to catch invalid dates like 32-13-2025)
      if (
        dateObject.getFullYear() !== year ||
        dateObject.getMonth() + 1 !== month ||
        dateObject.getDate() !== day
      ) {
        return "Invalid date";
      }
    
      return dateObject.toString(); // Outputs in local timezone
    }
    

    getNarration = (dataObj) => {
      let narration = "";
      if (dataObj.invoice_number) {
        narration += "Invoice#: " + dataObj.invoice_number;
      }
      if (dataObj.date) {
        narration += " Date: " + dataObj.date;
      }
      if(dataObj.inventory_details.line_items.length > 0 && dataObj.inventory_mode == 0  && this.props.accounting_mode == 1 && this.props.is_service_description_voucher){
        // let line_data = ""
        // this.state.line_items_data.map((item) =>{
        // 	line_data += ` ${item.line_item_name} ,`
        // })
        let line_data = dataObj.inventory_details.line_items
        .filter(item => item.line_item_name)  // Ensure only valid names are considered
        .map(item => item.line_item_name)     // Extract names
        .join(', ');
        narration += " Line Items: " + line_data
        
      }
      if (dataObj.additional_information) {
        narration += " Addl Info: " + dataObj.additional_information;
      }
      console.log("narrration",narration)
      return narration
    }

    handleInventory = (lineItems , dataObj) =>{
      let inventory_details = {
        "line_item_total": "",
        "line_item_gst_total": "",
        "line_item_discount_total": "",
        "inventory_voucher_status":   this.SNAPSHOT_LEDGER_ENTRIES_CODE ? true : false,
        "is_common_item_ledger": "",
        "accounting_narration": "",
        "line_items":[],
        // "line_items": [{
        // 	"line_item_id": "",
        // 	"line_item_id_prediction_status" : 0,
        // 	"line_item_name": "",
        // 	"line_item_name_prediction_status" : 0,
        // 	"hsn_sac": "",
        // 	"hsn_sac_prediction_status" : 0,
        // 	"rate": "",
        // 	"rate_prediction_status" : 0,
        // 	"per": "",
        // 	"per_prediction_status" : 0,
        // 	"quantity": "",
        // 	"quantity_prediction_status" : 0,
        // 	"gst_rate": "",
        // 	"gst_rate_prediction_status" : 0,
        // 	"discount_rate": "",
        // 	"discount_rate_prediction_status" : 0,
        // 	"amount": "",
        // 	"amount_prediction_status" : 0,
        // 	"item_ledger_guid": "",
        // 	"item_ledger_guid_prediction_status" : 0,
        // 	"item_ledger_id": null,
        // 	"item_ledger_id_prediction_status" : 0,
        // 	"item_ledger_name": "",
        // 	"item_ledger_name_prediction_status" : 0,
        // 	"common_item_ledger_guid": "",
        // 	"common_item_ledger_guid_prediction_status" : 0,
        // 	"common_item_ledger_id": "",
        // 	"common_item_ledger_id_prediction_status" : 0,
        // 	"common_item_ledger_name": "",
        // 	"common_item_ledger_name_prediction_status" : 0,
        // 	"line_item_update": 0
        // }],
        "ledgers": [{
          "amount": "",
          "rate": "",
          "guid": "",
          "ledger_id": "",
          "name": "",
        }],
        "sync_datetime": null,
        "sync_error": null,
        "sync_status": 0,
        "voucher_total_amount": "",
        "voucher_creation_date": "",
        "voucher_creation_date_prediction_status":0,
        "voucher_type": "",
        "party_ledger_guid": null,
        "party_ledger_guid_prediction_status":0,
        "party_ledger_id": null,
        "party_ledger_id_prediction_status":0,
        "party_ledger_name": null,
        "party_ledger_name_prediction_status":0,
        "common_item_ledger_guid": null,
        "common_item_ledger_guid_prediction_status":0,
        "common_item_ledger_id": null,
        "common_item_ledger_id_prediction_status":0,
        "common_item_ledger_name": null,
        "common_item_ledger_name_prediction_status":0,
        "parent_voucher_type_id":null,
        "voucher_type_guid":null,
        "voucher_type_name":null,
        "voucher_type_id":null,
      }
  
      if (inventory_details.is_common_item_ledger == "")
        inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;
  
  
    const dbLine =   lineItems.map((item) => {
            console.log("line item",item)
        let line_item = {
          "line_item_id": 0,
          "line_item_id_prediction_status" : 1,
          "line_item_name": item.item_name || "",
          "line_item_name_prediction_status" : !!item.item_name ? 1 : 0,
          "hsn_sac":  item.hsn?item.hsn.toString() || "":"",
          "hsn_sac_prediction_status" : !!item.hsn ? 1 : 0,
          "rate": (parseFloat(item.rate)) || "",
          "rate_prediction_status" : !!item.rate ? 1 : 0,
          "per": item.per || "",
          "per_prediction_status" : !!item.per ? 1 : 0,
          "quantity": item.quantity || "",
          "quantity_prediction_status" : !!item.quantity ? 1 : 0,
          "gst_rate": parseFloat(item.gst_rate) || "",
          "gst_rate_prediction_status" :  !!item.gst_rate  ? 1 : 0,
          "discount_rate": parseFloat(item.discount_rate || 0) || 0,
          "discount_rate_prediction_status" : !!item.discount_rate ? 1 : 0,
          "amount": (parseFloat(item.amount)) || "",
          "amount_prediction_status" : !!item.amount ? 1 : 0,
          "item_ledger_guid": item.item_ledger_guid || "",
          "item_ledger_guid_prediction_status" : !!item.item_ledger_guid ? 1 : 0,
          "item_ledger_id": item.item_ledger_id || null,
          "item_ledger_id_prediction_status" : !!item.item_ledger_id ? 1 : 0,
          "item_ledger_name": item.item_ledger_name || "",
          "item_ledger_name_prediction_status" : !!item.item_ledger_name ? 1 : 0,
          "common_item_ledger_guid": item.common_item_ledger_guid || "",
          "common_item_ledger_guid_prediction_status" : !!item.common_item_ledger_guid ? 1 : 0,
          "common_item_ledger_id": item.common_item_ledger_id || "",
          "common_item_ledger_id_prediction_status" : !!item.common_item_ledger_id ? 1 : 0,
          "common_item_ledger_name": item.common_item_ledger_name || "",
          "common_item_ledger_name_prediction_status" : !!item.common_item_ledger_name ? 1 : 0,
          "line_item_update": 1
        }
  
        if(!line_item.amount){
          if(line_item.quantity && line_item.rate){
            console.log("here in calculate amount")
            line_item.amount = parseFloat((line_item.rate || 0) * (line_item.quantity))
          }
          
        }

        if(!line_item.quantity){
          if(line_item.amount && line_item.rate){
            const quat = parseInt((line_item.amount || 0) / line_item.rate)
            line_item.quantity = quat > 0 ? quat : 0
          }
          
        }
        //finding the line item in line item list if present then prefill data
        let line_items_filter = this.state.line_items_list.find(line =>
          line.name.toLowerCase() == line_item.line_item_name.toLowerCase()
        );
  
        console.log("line item list",line_items_filter)
  
        if (line_items_filter != undefined) {
          line_item.line_item_name = line_items_filter.name;
          line_item.line_item_id = line_items_filter.id;
          if(!line_item.hsn_sac ){
            line_item.hsn_sac = line_items_filter.hsn_sac;
          }
          if(!line_item.gst_rate){
            line_item.gst_rate = line_items_filter.gst_rate;
          }
         
            //checking item ledger guid for perticular line items 
            if (line_items_filter.item_ledger_guid != "" && line_items_filter.item_ledger_guid != null) {
              //after finding item ledger in item ledger list prefill item data
                      let item_ledger = this.state.item_ledger_list.filter(item_ledger =>
                        item_ledger.guid == line_items_filter.item_ledger_guid
                      );
                      console.log("itemledger",item_ledger)
                      if (item_ledger.length > 0) {
                        line_item.item_ledger_guid = item_ledger[0].guid;
                        line_item.item_ledger_id = item_ledger[0].id;
                        line_item.item_ledger_name = item_ledger[0].name;
              
                        // Prediction status
                        line_item.item_ledger_guid_prediction_status = 1;
                        line_item.item_ledger_id_prediction_status = 1;
                        line_item.item_ledger_name_prediction_status = 1;
                      }
                      
                      // is commmon item ledger is zero that times prefill common item at inventory level
                      if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
                        if (inventory_details.is_common_item_ledger == 0 && dataObj.type == 1 && item.sales_ledger_guid) {
                          let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.sales_ledger_guid);
                          if (ledger.length > 0) {
                            line_item.common_item_ledger_guid = ledger[0].guid;
                            line_item.common_item_ledger_id = ledger[0].id;
                            line_item.common_item_ledger_name = ledger[0].name;
                  
                            line_item.common_item_ledger_guid_prediction_status = 1;
                            line_item.common_item_ledger_id_prediction_status = 1;
                            line_item.common_item_ledger_name_prediction_status = 1;
                          }
                        }
                        if (inventory_details.is_common_item_ledger == 0 && dataObj.type == 2 && item.purchase_ledger_guid) {
                          let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.purchase_ledger_guid);
                          if (ledger.length > 0) {
                            line_item.common_item_ledger_guid = ledger[0].guid;
                            line_item.common_item_ledger_id = ledger[0].id;
                            line_item.common_item_ledger_name = ledger[0].name;
                  
                            line_item.common_item_ledger_guid_prediction_status = 1;
                            line_item.common_item_ledger_id_prediction_status = 1;
                            line_item.common_item_ledger_name_prediction_status = 1;
                          }
                        }
                      }
                    }
          }
          
          console.log('lineitem', line_item);
          return line_item;
      })
     
      inventory_details.line_items = dbLine;
      //prefill party and purchase or  sales ledger based merchnat party and purchase or sales guid
      if(this.SNAPSHOT_LEDGER_ENTRIES_CODE){
       
        if (inventory_details.is_common_item_ledger == "")
          inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;
        console.log("here ",inventory_details.is_common_item_ledger)
        if(inventory_details.is_common_item_ledger && dataObj.type == 2 && !!dataObj.merchant_option.purchase_ledger_guid){
          let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == dataObj.merchant_option.purchase_ledger_guid);
          console.log("ledgers",ledger)
          if(ledger.length > 0){
            // inventory_details.common_item_ledger_guid = ledger[0].guid;
            // inventory_details.common_item_ledger_id = ledger[0].id;
            // inventory_details.common_item_ledger_name = ledger[0].name;
            // inventory_details.inventory_voucher_status = true;
    
            //prediction status
            // inventory_details.common_item_ledger_guid_prediction_status = 1;
            // inventory_details.common_item_ledger_id_prediction_status = 1;
            // inventory_details.common_item_ledger_name_prediction_status = 1;
            console.log("state of inve",ledger)
            inventory_details.common_item_ledger_guid = ledger[0].guid;
            inventory_details.common_item_ledger_id = ledger[0].id;
            inventory_details.common_item_ledger_name = ledger[0].name;
            inventory_details.common_item_ledger_guid_prediction_status =  0;
            inventory_details.common_item_ledger_id_prediction_status = 0;
            inventory_details.common_item_ledger_name_prediction_status =0;
  
            
    
          }else{
            inventory_details.common_item_ledger_guid = "";
            inventory_details.common_item_ledger_id = "";
            inventory_details.common_item_ledger_name = "";
            inventory_details.common_item_ledger_guid_prediction_status =  0;
            inventory_details.common_item_ledger_id_prediction_status = 0;
            inventory_details.common_item_ledger_name_prediction_status =0;
          }
       }
       if(inventory_details.is_common_item_ledger && dataObj.type == 1 && !!dataObj.merchant_option.sales_ledger_guid){
        let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == dataObj.merchant_option.sales_ledger_guid);
        console.log("ledgers",ledger)
          if(ledger.length > 0){
            inventory_details.common_item_ledger_guid = ledger[0].guid;
            inventory_details.common_item_ledger_id = ledger[0].id;
            inventory_details.common_item_ledger_name = ledger[0].name;
            inventory_details.inventory_voucher_status = true;
    
            // prediction status
            inventory_details.common_item_ledger_guid_prediction_status = 0;
            inventory_details.common_item_ledger_id_prediction_status = 0;
            inventory_details.common_item_ledger_name_prediction_status = 0;
    
            console.log("state of inve ",ledger)
          }else{
            inventory_details.common_item_ledger_guid = "";
            inventory_details.common_item_ledger_id = "";
            inventory_details.common_item_ledger_name = "";
            inventory_details.common_item_ledger_guid_prediction_status =  0;
            inventory_details.common_item_ledger_id_prediction_status = 0;
            inventory_details.common_item_ledger_name_prediction_status =0;
          }
        }
        if(!!dataObj.merchant_option.party_ledger_guid){
          let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == dataObj.merchant_option.party_ledger_guid);
          console.log("partyyy",party_ledger)
          if(party_ledger.length > 0){
            inventory_details.party_ledger_guid = party_ledger[0].guid;
            inventory_details.party_ledger_id = party_ledger[0].id;
            inventory_details.party_ledger_name = party_ledger[0].name;
            inventory_details.inventory_voucher_status = true;
    
            //predicton status
    
            inventory_details.party_ledger_guid_prediction_status = 0;
            inventory_details.party_ledger_id_prediction_status = 0;
            inventory_details.party_ledger_name_prediction_status = 0;
          }else{
                inventory_details.party_ledger_guid = "";
                inventory_details.party_ledger_id = "";
                inventory_details.party_ledger_name = "";
  
                // predicton status
  
                inventory_details.party_ledger_guid_prediction_status = 0;
                inventory_details.party_ledger_id_prediction_status = 0;
                inventory_details.party_ledger_name_prediction_status = 0;
          }
        }
  
  
     
    }else{
  
          inventory_details.common_item_ledger_guid = "";
          inventory_details.common_item_ledger_id = "";
          inventory_details.common_item_ledger_name = "";
  
          // prediction status
          inventory_details.common_item_ledger_guid_prediction_status = 0;
          inventory_details.common_item_ledger_id_prediction_status = 0;
          inventory_details.common_item_ledger_name_prediction_status = 0;
  
          inventory_details.party_ledger_guid = "";
          inventory_details.party_ledger_id = "";
          inventory_details.party_ledger_name = "";
  
          // predicton status
  
          inventory_details.party_ledger_guid_prediction_status = 0;
          inventory_details.party_ledger_id_prediction_status = 0;
          inventory_details.party_ledger_name_prediction_status = 0;
    }

    //prefill voucher type based on 

    let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (dataObj.type == 1 ? dataObj.merchant_option.sales_voucher_type_guid : dataObj.type == 2 ? dataObj.merchant_option.purchase_voucher_type_guid : ""))
								if(voucher_type_details.length > 0){
                  inventory_details.voucher_type_guid = voucher_type_details[0].guid ;
                  inventory_details.voucher_type_id = voucher_type_details[0].id ;
                  inventory_details.voucher_type_name = voucher_type_details[0].name ;
                  inventory_details.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id ;
								}else{
                  if(dataObj.type == 1){
                    let voucher = this.state.voucherTypeList.filter(voucher => voucher.name == 'Sales')
                    if(voucher.length > 0){
                        inventory_details.voucher_type_guid = voucher[0].guid ;
                        inventory_details.voucher_type_id = voucher[0].id ;
                        inventory_details.voucher_type_name = voucher[0].name ;
                        inventory_details.parent_voucher_type_id = voucher[0].parent_voucher_type_id ;
                    }
                  }
                  if(dataObj.type == 2){
                    let voucher = this.state.voucherTypeList.filter(voucher => voucher.name == 'Purchase')
                    if(voucher.length > 0){
                        inventory_details.voucher_type_guid = voucher[0].guid ;
                        inventory_details.voucher_type_id = voucher[0].id ;
                        inventory_details.voucher_type_name = voucher[0].name ;
                        inventory_details.parent_voucher_type_id = voucher[0].parent_voucher_type_id ;
                    }
                  }
                }
     
    inventory_details.voucher_creation_date = this.convertToFullDate(dataObj.date)
    inventory_details.line_item_total = this.calculateTotalLineItemAmt(inventory_details.line_items);
    inventory_details.line_item_gst_total = this.calculateTotalLineItemGST(inventory_details.line_items);
  
    console.log("inventory details",inventory_details)

    return inventory_details;
  
  }
  
   //calculate total line items combined total amt
   calculateTotalLineItemAmt = (line_item) => {
    let line_items = [...line_item];
    let amt = 0;
  
    line_items.forEach((item, index) => {
      if (item.amount) amt += parseFloat(item.amount);
    });
  
    //this.setState({line_item_total: amt.toFixed(2)})
    return amt.toFixed(2);
  };
  
  calculateTotalLineItemGST = (line_item) => {
    let line_items = [...line_item];
    let total_gst_amt = 0;
  
    line_items.forEach((item, index) => {
      if (item.gst_rate && item.amount)
        total_gst_amt +=
          (parseFloat(item.gst_rate) / 100) * parseFloat(item.amount);
    });
    //this.setState({line_item_gst_total: total_gst_amt.toFixed(2)})
    return total_gst_amt.toFixed(2);
  };
  
  
    checkForReadyStateOnDrop =  (initialData) => {
      let errors = {};
      if(initialData.disabled){
         errors = {}
      }else{
        
        let valid = false;
        let dataObj = initialData;
        let error_result = {}
    
    
        let data = {
          gst_no: dataObj.gst_no,
          category_id: dataObj.category_id,
          pan_number: dataObj.pan_number,
          tags: dataObj.tags,
          type: dataObj.type,
          date: changeDateFormate(new Date(dataObj.date)),
          merchant_name: dataObj.merchant_option.label || null,
          invoice_number: dataObj.invoice_number,
          total_amount: parseFloat(dataObj.total_amount),
          gst_status: dataObj.gst_status,
          sgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.sgst_amount)
            : null,
          cgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.cgst_amount)
            : null,
          igst_amount: dataObj.gst_status
            ? parseFloat(dataObj.igst_amount)
            : null,
          gst_total_amount: dataObj.gst_status
            ? parseFloat(dataObj.gst_total_amount)
            : null,
          tds_status: dataObj.tds_status ? 1 : 0,
          tds_percentage: dataObj.tds_status
            ? parseFloat(dataObj.tds_percentage)
            : null,
          tds_amount: dataObj.tds_status
            ? parseFloat(dataObj.tds_amount)
            : null,
          // "payment_status": dataObj.payment_status,
          // "payment_information": dataObj.payment_information,
          accounting_mode: this.props.accounting_mode,
          is_igst: dataObj.is_igst,
          party_ledger_guid: dataObj.party_ledger_guid,
          inventory_mode: dataObj.inventory_mode,
          is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
          // entry_status:this.state.isReady?2:1
        };
        console.log("ondrop in func")
          error_result =  validateCreateEvidenceWithReadyState(data,this.state.isReady);
          
            if (Object.keys(error_result).length === 0) {
              errors = {};
            } else {
              errors = {
                ...error_result,
                entry_status: "Please fill the mandatory details.",
              };
            }
          
    
    
    
    
      }
      console.log("ondrop",errors)
       return errors;
      };
  
    checkForReadyStateIgnoreMsgOndrop = (dataObj) =>{
        let ignoreErrors = {};
        let data = {
          category_id: dataObj.category_id ? dataObj.category_id : "",
          isNewCategory: dataObj.isNewCategory,
          newCategoryName: dataObj.newCategoryName,
          tags: dataObj.tags,
          merchant_name: dataObj.merchant_option.label || null,
          disabledIgnore: dataObj.disabledIgnore,
        };
    
        ignoreErrors = this.ignoreFunction(data);
    
        return ignoreErrors;
    
    
      }
      
  
    calculateTotalGst = (rowIndex) => {
      let gst_total_amount =
        parseFloat(this.state.excelData[rowIndex].sgst_amount || 0) +
        parseFloat(this.state.excelData[rowIndex].cgst_amount || 0) +
        parseFloat(this.state.excelData[rowIndex].igst_amount || 0);
  
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, gst_total_amount: gst_total_amount } : row
      );
      this.setState({ excelData: updatedData }, () => {
        console.log("After Updated Data 555555 --->", this.state.excelData);
        this.calculateTDS(rowIndex);
      });
    };
  
    calculateTDS = (rowIndex) => {
      let total_amt =
        parseFloat(this.state.excelData[rowIndex].total_amount || 0) -
        parseFloat(this.state.excelData[rowIndex].gst_total_amount || 0);
      let tds_amount =
        (total_amt *
          parseFloat(this.state.excelData[rowIndex].tds_percentage || 0)) /
        100;
  
      console.log("tds amount value -------------", tds_amount);
      if (tds_amount < 0) tds_amount = 0;
      const updatedData = this.state.excelData.map((row, index) =>
        // index === rowIndex ? { ...row, tds_amount: tds_amount.toFixed(2) } : row
      index === rowIndex ? { ...row, tds_amount: this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds_amount) : tds_amount.toFixed(2) } : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyState(rowIndex);
      });
    };
  
    amountRoundOff = (name,rowIndex) =>{
      let excelData = [...this.state.excelData];
      let updatedRow = {...excelData[rowIndex]}
      if(name == 'cgst_amount'){
        console.log("here in cgst", this.props.amount_round_off)
          updatedRow.cgst_amount = updatedRow.cgst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.cgst_amount) : updatedRow.cgst_amount : updatedRow.cgst_amount;
      }
      if(name == 'sgst_amount'){
        updatedRow.sgst_amount = updatedRow.sgst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.sgst_amount) : updatedRow.sgst_amount : updatedRow.sgst_amount;
      }
      if(name == 'igst_amount'){
        updatedRow.igst_amount = updatedRow.igst_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.igst_amount) : updatedRow.igst_amount : updatedRow.igst_amount;
      }
      if(name == 'total_amount'){
        updatedRow.total_amount = updatedRow.total_amount ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.total_amount) : updatedRow.total_amount : updatedRow.total_amount;
      }
      if(name == 'tds_percentage'){
        updatedRow.tds_percentage = updatedRow.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,updatedRow.tds_percentage) : excelData[rowIndex].tds_percentage : excelData[rowIndex].tds_percentage;
      }
      console.log("here in cgst",updatedRow)
      excelData[rowIndex] = updatedRow;
      console.log("here in rowindex",excelData[rowIndex])
      this.setState({excelData:excelData})
  }
  
  
    amountChange = (name, value, rowIndex) => {
      if (value > 999999999.99) {
        return;
      }
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, [name]: value, is_dirty: true } : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.checkForReadyState(rowIndex);
      });
    };
    finishImportExcelPopup = () => {
      this.props.finishImportExcelPopup(this.state.excelData);
      this.setState({ excelData: [], isDownload: false, isReady: false });
      this.getSnapshotMasters();
    };
    onMerchantChanged = async (merchant_option, is_edit = false, rowIndex) => {
      console.log("merchant option -----------------", merchant_option.__isNew__);
      const newState = await Promise.all(
        this.state.excelData.map(async (row, index) => {
          if (index === rowIndex) {
            const updatedRow = {
              ...row,
            };
  
            let merchant = merchant_option || {};
            let selected_tags = [];
  
            if (
              Object.keys(merchant_option).length > 0 &&
              merchant_option.snapshot_type != null &&
              !is_edit
            ) {
              let expense = 1;
              let snapshot_type = this.state.snapshot_types.find(
                (type) => type.id == merchant_option.snapshot_type
              );
              expense = snapshot_type.expense == 1 ? 1 : 0;
  
              if (expense == 0 && row.is_perquisite == true) {
                if (
                  await confirm({
                    confirmation:
                      "This action will uncheck the perquisite checkbox. Do you still want to continue?",
                  })
                ) {
                  updatedRow.expense = 0;
                  updatedRow.is_perquisite = false;
                  updatedRow.type = merchant_option.snapshot_type;
                  if(updatedRow.type == 2 || updatedRow.type == 1){
                    updatedRow.inventory_mode = true
                  }else{
                    updatedRow.inventory_mode = false
                  }
                } else {
                  return updatedRow;
                }
              }
              selected_tags = merchant_option.tags.map((tag, index) => {
                return tag.id;
              });
            }

            if (merchant_option.__isNew__) {
           
                updatedRow.inventory_details.inventory_common_item_ledger_guid = "";
                updatedRow.inventory_details.inventory_common_item_ledger_id="";
                updatedRow.inventory_details.inventory_common_item_ledger_name="";
                updatedRow.inventory_details.inventory_common_item_ledger_guid_prediction=0;
                updatedRow.inventory_details.inventory_common_item_ledger_id_prediction=0;
                updatedRow.inventory_details.inventory_common_item_ledger_name_prediction=0;
        
                updatedRow.inventory_details.inventory_party_ledger_guid="";
                updatedRow.inventory_details.inventory_party_ledger_id="";
                updatedRow.inventory_details.inventory_party_ledger_name="";
                updatedRow.inventory_details.inventory_party_ledger_guid_prediction=0;
                updatedRow.inventory_details.inventory_party_ledger_id_prediction=0;
                updatedRow.inventory_details.inventory_party_ledger_name_prediction=0;
                updatedRow.inventory_details.voucher_type_guid="";
                updatedRow.inventory_details.voucher_type_id="";
                updatedRow.inventory_details.voucher_type_name="";
                updatedRow.inventory_details.parent_voucher_type_id="";
           
            }

            (updatedRow.merchant_id = merchant.value),
              (updatedRow.merchant_name = merchant.label),
              (updatedRow.merchant_option = merchant);
              (updatedRow.gst_override = false);
  
            if (!merchant_option.__isNew__) {
              updatedRow.gst_no =
                merchant_option.gst_no ||
                (merchant_option.__isNew__ || merchant_option.is_edit == 1
                  ? row.gst_no
                  : "");
  
              updatedRow.pan_number =
                merchant_option.pan_number ||
                (merchant_option.__isNew__ || merchant_option.is_edit == 1
                  ? row.pan_number
                  : "");
            }
            if (merchant_option.__isNew__ === false) {
              updatedRow.gst_no =
                merchant_option.gst_no ||
                (merchant_option.__isNew__ || merchant_option.is_edit == 1
                  ? row.gst_no
                  : "");
  
              updatedRow.pan_number =
                merchant_option.pan_number ||
                (merchant_option.__isNew__ || merchant_option.is_edit == 1
                  ? row.pan_number
                  : "");
              updatedRow.is_dirty = true;
              updatedRow.is_edit_supplier = is_edit;
              updatedRow.is_msme =
                merchant_option.is_msme ||
                (merchant_option.__isNew__ || merchant_option.is_edit == 1
                  ? row.is_msme
                  : false);
              updatedRow.tags = selected_tags ? selected_tags : [];
              let customer_gst = updatedRow.gst_no
                ? updatedRow.gst_no.slice(0, 2)
                : null || null;
              let entity_gst = !!this.props.entity_gst_no
                ? this.props.entity_gst_no.slice(0, 2)
                : null;
              console.log(customer_gst, entity_gst);
  
              if (customer_gst == entity_gst && customer_gst && entity_gst) {
                updatedRow.is_igst = false;
                updatedRow.igst_amount = "";
                updatedRow.disable_igst = true;
                updatedRow.hide_gst_override= false;
              } else if (!customer_gst || !entity_gst) {
                updatedRow.is_igst = true;
                updatedRow.sgst_amount = "";
                updatedRow.cgst_amount = "";
                updatedRow.disable_igst = false;
                updatedRow.hide_gst_override = true;
              } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
                updatedRow.is_igst = true;
                updatedRow.disable_igst = true;
                updatedRow.sgst_amount = "";
                updatedRow.cgst_amount = "";
                updatedRow.hide_gst_override = false;
              } else {
                updatedRow.is_igst = false;
                updatedRow.igst_amount = "";
                updatedRow.disable_igst = false;
                updatedRow.hide_gst_override = true;
              }
  
              if (
                !merchant_option.__isNew__ &&
                merchant_option.value != 0 &&
                !is_edit
              ) {
                updatedRow.category_id = merchant_option.category_id || "";
                updatedRow.selected_tags = selected_tags;
  
                if (Object.keys(merchant_option).length > 0) {
                  if (!row.is_reimbursement)
                  {
                      updatedRow.type = merchant_option.snapshot_type || ""; // });
                      if(updatedRow.type == 2 || updatedRow.type == 1){
                        updatedRow.inventory_mode = true
                      }else{
                        updatedRow.inventory_mode = false
                      }
                  }
                  let expense = 1;
  
                  let snapshot_type = this.state.snapshot_types.find(
                    (type) => type.id == merchant_option.snapshot_type
                  );
                  let merchant_type = 2;
  
                  if (snapshot_type != null) {
                    expense = snapshot_type.expense == 1 ? 1 : 0;
                    merchant_type = snapshot_type.merchant_type;
                  } else {
                    expense = 1;
                  }
  
                  updatedRow.expense = expense;
                  updatedRow.merchant_type = merchant_type;
                  updatedRow.is_perquisite =
                    expense && row.is_perquisite ? true : false;
                } else {
                  if (!row.is_reimbursement) {
                    updatedRow.type = ""; // });
                    updatedRow.inventory_mode = false
                  }
  
                  updatedRow.expense = 1;
                  updatedRow.merchant_type = 2;
                  updatedRow.is_perquisite = row.is_perquisite ? true : false;
                }
  
                if (!!merchant_option.tds_percentage && !is_edit) {
                  updatedRow.tds_status = true;
                  updatedRow.tds_percentage = merchant_option.tds_percentage;
                } else {
                  updatedRow.tds_status = false;
                  updatedRow.tds_percentage = "";
                  updatedRow.tds_amount = "";
                }
              }
  
            }
  
            if (Object.keys(merchant_option).length > 0 && !merchant_option.__isNew__ && !is_edit && (updatedRow.selected_tags.length > 0 || !!updatedRow.category_id || !!updatedRow.type) && merchant_option.value != 0) {
              console.log("this.props",this.props.accounting_mode)
              if (this.props.accounting_mode == 2) {
                  if (updatedRow.inventory_mode == 1 || updatedRow.inventory_mode) {
        
                    if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
                      let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == (merchant_option.snapshot_type == 1 ? merchant_option.sales_ledger_guid : merchant_option.purchase_ledger_guid));
                      console.log("merchant ledger",ledger)
                      if (updatedRow.inventory_details.is_common_item_ledger == "")
                      updatedRow.inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;
        
                      if (ledger.length > 0 && updatedRow.inventory_details.is_common_item_ledger) {
                        updatedRow.inventory_details.common_item_ledger_guid = ledger[0].guid;
                        updatedRow.inventory_details.common_item_ledger_id = ledger[0].id;
                        updatedRow.inventory_details.common_item_ledger_name = ledger[0].name;
                        updatedRow.inventory_details.inventory_voucher_status = true;
                        // updatedRow.inventory_details.inventory_common_item_ledger_guid=ledger[0].guid;
                        // inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
                       // this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
                      }
        
                      let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == merchant_option.party_ledger_guid);
                      //console.log(party_ledger)
                      if (party_ledger.length > 0) {
                        updatedRow.inventory_details.party_ledger_guid = party_ledger[0].guid;
                        updatedRow.inventory_details.party_ledger_id = party_ledger[0].id;
                        updatedRow.inventory_details.party_ledger_name = party_ledger[0].name;
                        updatedRow.inventory_details.inventory_voucher_status = true;
                        // this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
                        //   this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
        
                      }
                      let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (merchant_option.snapshot_type == 1 ? merchant_option.sales_voucher_type_guid : merchant_option.purchase_voucher_type_guid))
                      if(voucher_type_details.length > 0){
                        updatedRow.inventory_details.voucher_type_guid=voucher_type_details[0].guid;
                        updatedRow.inventory_details.voucher_type_id=voucher_type_details[0].id;
                        updatedRow.inventory_details.voucher_type_name=voucher_type_details[0].name;
                        updatedRow.inventory_details.parent_voucher_type_id=voucher_type_details[0].parent_voucher_type_id;
                      }else{
                        let voucher = this.state.voucherTypeList.filter(voucher => voucher.name == (merchant_option.snapshot_type == 1 ? 'Sales' : 'Purchase'))
                        if(voucher.length > 0){
                          updatedRow.inventory_details.voucher_type_guid=voucher[0].guid;
                          updatedRow.inventory_details.voucher_type_id=voucher[0].id;
                          updatedRow.inventory_details.voucher_type_name=voucher[0].name;
                          updatedRow.inventory_details.parent_voucher_type_id=voucher[0].parent_voucher_type_id;
                        }else{
                          updatedRow.inventory_details.parent_voucher_type_id="";
                          updatedRow.inventory_details.voucher_type_guid="";
                          updatedRow.inventory_details.voucher_type_id="";
                          updatedRow.inventory_details.voucher_type_name=""
                        }
                      }
                    } 
                    else{
                      updatedRow.inventory_details.common_item_ledger_guid = "";
                      updatedRow.inventory_details.common_item_ledger_id = "";
                      updatedRow.inventory_details.common_item_ledger_name = "";
                      updatedRow.inventory_details.party_ledger_guid = "";
                      updatedRow.inventory_details.party_ledger_id = "";
                      updatedRow.inventory_details.party_ledger_name = "";
                      updatedRow.inventory_details.parent_voucher_type_id="";
                      updatedRow.inventory_details.voucher_type_guid="";
                      updatedRow.inventory_details.voucher_type_id="";
                      updatedRow.inventory_details.voucher_type_name=""
                    }
                  }
                  await confirm({
                    confirmation: "Existing Tags, Category," + (updatedRow.is_reimbursement ? "" : "Type") + " TDS values, Party Ledger, Purchase/Sales Ledger may change.",
                    options: { type: "alert" }
                  })
              } else {
                await confirm({
                  confirmation: "Existing Tags, Category," + (updatedRow.is_reimbursement ? "" : "Type") + " TDS values, may change.",
                  options: { type: "alert" }
                })
              }
            }
            if (is_edit) {
              return updatedRow;
            }
  
            if (!!merchant_option.gst_no) {
              updatedRow.gst_status = true;
              updatedRow.is_gst_claim_eligible = true;
            }
            //chnage the prediction status
            updatedRow.merchant_id_prediction_status = 0;
  
            return updatedRow;
          } else {
            return row;
          }
        })
      );
  
      this.setState({ excelData: newState }, () => {
        console.log(
          "update merchant data after================================ ",
          this.state.excelData
        );
        this.hideShowOnTypeChange(this.state.excelData[rowIndex].type, rowIndex);
      });
    };
  
    hideShowOnTypeChange = (value, rowIndex) => {
      let tds_status = this.state.excelData[rowIndex].tds_status;
      let payment_status = this.state.excelData[rowIndex].payment_status;
      let tds_percentage = this.state.excelData[rowIndex].tds_percentage;
      let tds_amount = this.state.excelData[rowIndex].tds_amount;
  
      if (value == 5 || value == 4 || value == 9 || value == 10) {
        tds_status = false;
        payment_status = false;
        tds_percentage = "";
        tds_amount = "";
      }
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? { ...row, tds_status, payment_status, tds_percentage, tds_amount }
          : row
      );
      this.setState({ excelData: updatedData }, () => {
        this.calculateTotalGst(rowIndex);
      });
    };
    handleGstOverrideChange = (e,rowIndex) =>{
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      if(name == 'gst_override' && value == true){
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex ? { ...row, [name]: value, is_dirty: true,disable_igst:false } : row
        );
        this.setState({excelData:updatedData})
      }else{
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex ? { ...row, [name]: value, is_dirty: true, } : row
        );
        this.setState({excelData:updatedData},()=>{
          let customer_gst = this.state.excelData[rowIndex].gst_no ? this.state.excelData[rowIndex].gst_no.slice(0, 2) : null || null;
          let entity_gst = !!this.props.entity_gst_no
          ? this.props.entity_gst_no.slice(0, 2)
          : null;
  
          if (customer_gst == entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: false,
                  igst_amount: "",
                  disable_igst: true,
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (!customer_gst || !entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                   sgst_amount: "",
                   cgst_amount: "",
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                  disable_igst: true,
                    sgst_amount: "",
                    cgst_amount: "",
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: false,
                    igst_amount: "",
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          }
          
        })
      }
  
    }
    handleChange = (e, rowIndex) => {
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      const updatedData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, [name]: value, is_dirty: true } : row
      );
      this.setState({ excelData: updatedData }, () => {
        if (target.type === "checkbox")
          this.checkForReadyState(rowIndex);
      });
      // }
    };
  
    checkForReadyIgnoreMsg =  (rowIndex) => {
     
      let excelData = [...this.state.excelData]
      let updatedRow = {...excelData[rowIndex]}
        let ignoreErrors = {};
        let dataObj = updatedRow;
        console.log(
          "dataobject ------------- ",
          dataObj,
          rowIndex,
          Date(dataObj.date)
        );
  
        let data = {
          category_id: dataObj.category_id ? dataObj.category_id : "",
          isNewCategory: dataObj.isNewCategory,
          newCategoryName: dataObj.newCategoryName,
          tags: dataObj.tags,
          merchant_name: dataObj.merchant_option.label || null,
          disabledIgnore: dataObj.disabledIgnore,
        };
        ignoreErrors = this.ignoreFunction(data);
  
        updatedRow.ignoreErrors = ignoreErrors;
        
     
    // const updatedData = this.state.excelData.map((row, index) =>
    //   index === rowIndex ? { ...row, ignoreErrors: ignoreErrors } : row
    // );
    excelData[rowIndex] = updatedRow;
    this.setState({ excelData });
  };
  
  
    checkForDraftReadyIgnoreMsg = async () => {
      const newState = await Promise.all(
        this.state.excelData.map(async (row, index) => {
          const updatedRow = {
            ...row,
          };
  
          let ignoreErrors = {};
          let dataObj = updatedRow;
          console.log(
            "dataobject ------------- ",
            dataObj,
            // rowIndex,
            Date(dataObj.date)
          );
  
          let data = {
            category_id: dataObj.category_id ? dataObj.category_id : "",
            isNewCategory: dataObj.isNewCategory,
            newCategoryName: dataObj.newCategoryName,
            tags: dataObj.tags,
            merchant_name: dataObj.merchant_option.label || null,
            disabledIgnore: dataObj.disabledIgnore,
          };
          ignoreErrors = this.ignoreFunction(data);
  
          updatedRow.ignoreErrors = ignoreErrors;
          return updatedRow;
        }));
      // const updatedData = this.state.excelData.map((row, index) =>
      //   index === rowIndex ? { ...row, ignoreErrors: ignoreErrors } : row
      // );
      this.setState({ excelData: newState });
    };
  
    checkForReadyState =  (rowIndex) => {
      
        
      let excelData = [...this.state.excelData];
      let updatedRow = {...excelData[rowIndex]}
  
      if (updatedRow.disabled) {
        updatedRow.errors = {};
         //added inventory errors
         updatedRow.inventory_errors={};
         updatedRow.line_item_errors=[];
         updatedRow.ledger_errors = [];
      } else {
        let errors = {};
        let inventory_error = {}
        let valid = false;
        let dataObj = updatedRow;
  
        let data = {
          gst_no: dataObj.gst_no,
          category_id: dataObj.category_id,
          pan_number: dataObj.pan_number,
          tags: dataObj.tags,
          type: dataObj.type,
          date: changeDateFormate(new Date(dataObj.date)),
          merchant_name: dataObj.merchant_option.label || null,
          invoice_number: dataObj.invoice_number,
          total_amount: parseFloat(dataObj.total_amount),
          gst_status: dataObj.gst_status,
          sgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.sgst_amount)
            : null,
          cgst_amount: dataObj.gst_status
            ? parseFloat(dataObj.cgst_amount)
            : null,
          igst_amount: dataObj.gst_status
            ? parseFloat(dataObj.igst_amount)
            : null,
          gst_total_amount: dataObj.gst_status
            ? parseFloat(dataObj.gst_total_amount)
            : null,
          tds_status: dataObj.tds_status ? 1 : 0,
          tds_percentage: dataObj.tds_status
            ? parseFloat(dataObj.tds_percentage)
            : null,
          tds_amount: dataObj.tds_status
            ? parseFloat(dataObj.tds_amount)
            : null,
          // "payment_status": dataObj.payment_status,
          // "payment_information": dataObj.payment_information,
          accounting_mode: this.props.accounting_mode,
          is_igst: dataObj.is_igst,
          party_ledger_guid: dataObj.party_ledger_guid,
          inventory_mode: dataObj.inventory_mode,
          is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
          // entry_status:this.state.isReady?2:1
        };
  
        console.log("Error validation ---- ", rowIndex, data);
  
        errors = validateCreateEvidenceWithReadyState(
          data,
          this.state.isReady
        );
        console.log("gst---------------------", dataObj.gst_no);
        if (!errors.gst_no && dataObj.gst_no)
          updatedRow.pan_number = dataObj.gst_no.substring(2, 12);
        if (!dataObj.is_reimbursement) {
          if (Object.keys(errors).length === 0) {
            updatedRow.errors = {};
            updatedRow.entry_status = 2;
            updatedRow.is_dirty = true;
          } else {
            let errors_result = {
              ...errors,
              entry_status: "Please fill the mandatory details.",
            };
  
            updatedRow.errors = errors_result;
          }
        }

        //manage inventory errors
        let inventory_row_data = {
          "type": dataObj.type,
          //"voucher_creation_date": inventory_details.voucher_creation_date ?  inventory_details.voucher_creation_date : this.state.date || null ,
          "voucher_creation_date": dataObj.inventory_details.voucher_creation_date || data.date,
          "party_ledger_guid": dataObj.inventory_details.party_ledger_guid || null,
          "common_item_ledger_guid": dataObj.inventory_details.common_item_ledger_guid || null,
          "entry_status": this.state.isReady ? 2 : 1,
          "accounting_status": 3,
          "inventory_voucher_status": dataObj.inventory_details.inventory_voucher_status,
          "is_common_item_ledger": dataObj.inventory_details.is_common_item_ledger,
          "line_items": dataObj.inventory_details.line_items,
          "ledgers": dataObj.inventory_details.ledgers,
          "accounting_user": this.SNAPSHOT_LEDGER_ENTRIES_CODE,
        }
       
        inventory_error = validateExcelInventoryModeData(inventory_row_data);

        if (!dataObj.is_reimbursement) {
          if (Object.keys(inventory_error.errors).length == 0 && inventory_error.line_item_errors.length == 0 && inventory_error.ledger_errors.length == 0) {
            updatedRow.inventory_errors = {};
            updatedRow.line_item_errors = [];
            updatedRow.ledger_errors = []
          } else {
            updatedRow.inventory_errors = {...inventory_error.errors , inventory_error_status: 'Please fill required mandatory fields.'}
            updatedRow.line_item_errors = inventory_error.line_item_errors
            updatedRow.ledger_errors = inventory_error.ledger_errors
          }
        }
      }
  
  
      excelData[rowIndex] = updatedRow;
  this.setState({ excelData }, () => {
    this.checkForReadyIgnoreMsg(rowIndex);
  });
  };
  
  
  checkForDraftToReadyState = async () => {
  
      let updatedExcelData = this.state.excelData.map((row) => {
        let updatedRow = { ...row };
        if(this.state.isReady){
          updatedRow.entry_status = 2;
        }else{
          updatedRow.entry_status = 1;
        }

        if (updatedRow.disabled) {
          updatedRow.errors = {};
          //added inventory errors
          updatedRow.inventory_errors={};
          updatedRow.line_item_errors=[];
          updatedRow.ledger_errors = [];

        } else {
          let errors = {};
          let dataObj = updatedRow;
          let inventory_error = {}
  
          let data = {
            gst_no: dataObj.gst_no,
            category_id: dataObj.category_id,
            pan_number: dataObj.pan_number,
            tags: dataObj.tags,
            type: dataObj.type,
            date: changeDateFormate(new Date(dataObj.date)),
            merchant_name: dataObj.merchant_option.label || null,
            invoice_number: dataObj.invoice_number,
            total_amount: parseFloat(dataObj.total_amount),
            gst_status: dataObj.gst_status,
            sgst_amount: dataObj.gst_status
              ? parseFloat(dataObj.sgst_amount)
              : null,
            cgst_amount: dataObj.gst_status
              ? parseFloat(dataObj.cgst_amount)
              : null,
            igst_amount: dataObj.gst_status
              ? parseFloat(dataObj.igst_amount)
              : null,
            gst_total_amount: dataObj.gst_status
              ? parseFloat(dataObj.gst_total_amount)
              : null,
            tds_status: dataObj.tds_status ? 1 : 0,
            tds_percentage: dataObj.tds_status
              ? parseFloat(dataObj.tds_percentage)
              : null,
            tds_amount: dataObj.tds_status
              ? parseFloat(dataObj.tds_amount)
              : null,
            accounting_mode: this.props.accounting_mode,
            is_igst: dataObj.is_igst,
            party_ledger_guid: dataObj.party_ledger_guid,
            inventory_mode: dataObj.inventory_mode,
            is_gst_claim_eligible: dataObj.is_gst_claim_eligible,
          };
          errors = validateCreateEvidenceWithReadyState(data, this.state.isReady);
          if (!errors.gst_no && dataObj.gst_no) {
            updatedRow.pan_number = dataObj.gst_no.substring(2, 12);
          }
          if (!dataObj.is_reimbursement) {
            if (Object.keys(errors).length === 0) {
              updatedRow.errors = {};
              // updatedRow.entry_status = 2;
              updatedRow.is_dirty = true;
            } else {
              updatedRow.errors = {
                ...errors,
                entry_status: "Please fill the mandatory details.",
              };
            }
          }

          //manage inventory errors
          let inventory_row_data = {
            "type": dataObj.type,
            //"voucher_creation_date": inventory_details.voucher_creation_date ?  inventory_details.voucher_creation_date : this.state.date || null ,
            "voucher_creation_date": dataObj.inventory_details.voucher_creation_date || data.date,
            "party_ledger_guid": dataObj.inventory_details.party_ledger_guid || null,
            "common_item_ledger_guid": dataObj.inventory_details.common_item_ledger_guid || null,
            "entry_status": this.state.isReady ? 2 : 1,
            "accounting_status": 3,
            "inventory_voucher_status": dataObj.inventory_details.inventory_voucher_status,
            "is_common_item_ledger": dataObj.inventory_details.is_common_item_ledger,
            "line_items": dataObj.inventory_details.line_items,
            "ledgers": dataObj.inventory_details.ledgers,
            "accounting_user": this.SNAPSHOT_LEDGER_ENTRIES_CODE,
          }
         
          inventory_error = validateExcelInventoryModeData(inventory_row_data);

          if (!dataObj.is_reimbursement) {
            if (Object.keys(inventory_error.errors).length == 0 && inventory_error.line_item_errors.length == 0 && inventory_error.ledger_errors.length == 0) {
              updatedRow.inventory_errors = {};
              updatedRow.line_item_errors = [];
              updatedRow.ledger_errors = []
            } else {
              updatedRow.inventory_errors = {...inventory_error.errors , inventory_error_status: 'Please fill required mandatory fields.'}
              updatedRow.line_item_errors = inventory_error.line_item_errors
              updatedRow.ledger_errors = inventory_error.ledger_errors
            }
          }
        }
        let ignoreErrors = this.checkForReadyStateIgnoreMsgOndrop(updatedRow);
        updatedRow.ignoreErrors = ignoreErrors;
  
        return updatedRow;
      })
      this.setState({excelData:updatedExcelData});
    };
  
    onGSTChange = (e, rowIndex) => {
      let gst_no = e.target.value;
  
      if (!!gst_no) {
        if (
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no)
        ) {
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex
              ? { ...row, pan_number: gst_no.substring(2, 12), gst_status: true,gst_override:false }
              : row
          );
          this.setState({ excelData: updatedData }, () => {
            let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
            let entity_gst = !!this.props.entity_gst_no
              ? this.props.entity_gst_no.slice(0, 2)
              : null;
  
            if (customer_gst == entity_gst && customer_gst && entity_gst) {
              const updatedData = this.state.excelData.map((row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    is_igst: false,
                    //igst_amount: "",
                    disable_igst: true,
                    hide_gst_override:false
                  }
                  : row
              );
              this.setState({ excelData: updatedData }, () => {
                this.calculateTotalGst(rowIndex);
              });
            } else if (!customer_gst || !entity_gst) {
              const updatedData = this.state.excelData.map((row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    is_igst: true,
                    //  sgst_amount: "",
                    //  cgst_amount: "",
                    disable_igst: false,
                    hide_gst_override:true
                  }
                  : row
              );
              this.setState({ excelData: updatedData }, () => {
                this.calculateTotalGst(rowIndex);
              });
            } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
              const updatedData = this.state.excelData.map((row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    is_igst: true,
                    disable_igst: true,
                    //   sgst_amount: "",
                    //   cgst_amount: "",
                    hide_gst_override:false
                  }
                  : row
              );
              this.setState({ excelData: updatedData }, () => {
                this.calculateTotalGst(rowIndex);
              });
            } else {
              const updatedData = this.state.excelData.map((row, index) =>
                index === rowIndex
                  ? {
                    ...row,
                    is_igst: false,
                    //   igst_amount: "",
                    disable_igst: false,
                    hide_gst_override:true
                  }
                  : row
              );
              this.setState({ excelData: updatedData }, () => {
                this.calculateTotalGst(rowIndex);
              });
            }
          });
        } else {
          console.log("pan will be empty==== ");
          const updatedData = this.state.excelData.map((row, index) =>
            index === rowIndex ? { ...row, pan_number: "" } : row
          );
          this.setState({ excelData: updatedData }, () => {
            this.calculateTotalGst(rowIndex);
          });
        }
      } else {
        const updatedData = this.state.excelData.map((row, index) =>
          index === rowIndex
            ? { ...row,gst_override:false }
            : row
        );
        this.setState({excelData: updatedData},()=>{
          let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
          let entity_gst = !!this.props.entity_gst_no
            ? this.props.entity_gst_no.slice(0, 2)
            : null;
    
          if (customer_gst == entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row, is_igst: false,
                  //  igst_amount: "",
                  disable_igst: true,
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (!customer_gst || !entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                  // sgst_amount: "",
                  // cgst_amount: "",
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row,
                  is_igst: true,
                  disable_igst: true,
                  //  sgst_amount: "",
                  //  cgst_amount: "",
                  hide_gst_override:false
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          } else {
            const updatedData = this.state.excelData.map((row, index) =>
              index === rowIndex
                ? {
                  ...row, is_igst: false,
                  //  igst_amount: "", 
                  disable_igst: false,
                  hide_gst_override:true
                }
                : row
            );
            this.setState({ excelData: updatedData }, () => {
              this.calculateTotalGst(rowIndex);
            });
          }
        })
       
      }
    };
  
    handleTypeChange = async (e, rowIndex) => {
      const newState = await Promise.all(
        this.state.excelData.map(async (row, index) => {
          if (index === rowIndex) {
            const { name, value } = e.target;
            let oldVal = row[name];
            const updatedRow = {
              ...row,
              [name]: value,
            };
            if(value == 2 || value == 1){
              updatedRow.inventory_mode = true
            }else{
              updatedRow.inventory_mode = false
            }
            let expense = 1;
            let isPerquisiteDissable = false;
  
            let snapshot_type = this.state.snapshot_types.find(
              (type) => type.id == value
            );
            if((updatedRow.inventory_mode && updatedRow.inventory_details && updatedRow.inventory_mode && (value == 2 || value == 1)) ){
                  await oldconfirm({
                    confirmation: "Changing snapshot types may invalidate accounting voucher(s).Kindly review before saving !",
                    options: { type: "alert" }
                  })
                  // let inventory_details = _.cloneDeep(updatedRow.inventory_details)
                  updatedRow.inventory_details.common_item_ledger_guid = "";
                  updatedRow.inventory_details.common_item_ledger_id = "";
                  updatedRow.inventory_details.common_item_ledger_name = "";
                  updatedRow.inventory_details.common_item_ledger_guid_prediction_status=0;
                  updatedRow.inventory_details.common_item_ledger_id_prediction_status=0;
                  updatedRow.inventory_details.common_item_ledger_name_prediction_status =0;
                  updatedRow.inventory_details.voucher_type_guid = "";
                  updatedRow.inventory_details.voucher_type_id = "";
                  updatedRow.inventory_details.voucher_type_name = "";
                  updatedRow.inventory_details.parent_voucher_type_id = "";

                 
                }
            expense = snapshot_type ? snapshot_type.expense : 0;
            updatedRow.expense = expense;
            let merchant_type = snapshot_type.merchant_type;
            if (expense == 0 && row.is_perquisite == true) {
              if (
                await confirm({
                  confirmation:
                    "This action will uncheck the perquisite checkbox. Do you still want to continue?",
                })
              ) {
                // updatedRow.expense = 0;
                updatedRow.is_perquisite = false;
                updatedRow.merchant_type = merchant_type;
                isPerquisiteDissable = true;
              } else {
                updatedRow[name] = oldVal;
                return updatedRow;
              }
            }
            //   updatedRow.expense = expense;
            // })
            //  updatedRow.type = merchant_type;
            let merchant_option = row.merchant_option;
  
            if (Object.keys(merchant_option).length != 0) {
              let merchant_list = this.state.all_merchant_list;
              let merchant_data = merchant_list.find(
                (merchant) =>
                  merchant.gst_no == row.gst_no &&
                  merchant.label == merchant_option.label
              );
              merchant_option.value = !merchant_data ? 0 : merchant_data.value;
              updatedRow.merchant_option = merchant_option;
            }
  
            if (merchant_type == 2) {
              updatedRow.is_customer = false;
            } else {
              updatedRow.is_supplier = false;
            }
  
            return updatedRow;
          } else {
            return row;
          }
        })
      );
  
      this.setState({ excelData: newState }, () => {
        if (!this.state.excelData[rowIndex].is_supplier)
          this.handleSupplierCustomerToggle("is_supplier", true, rowIndex);
        if (!this.state.excelData[rowIndex].is_customer)
          this.handleSupplierCustomerToggle("is_customer", true, rowIndex);
        this.hideShowOnTypeChange(e.target.value, rowIndex);
      });
    };
  
    formatDateNew = (dateString) => {
      if (!dateString) return null;
  
      const date = dateString instanceof Date ? dateString : new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
  
      return `${day}-${month}-${year}`;
    };
  
    handleDateChange = (date, rowIndex) => {
      const formatDate = this.formatDateNew(date);
      const newData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, date: formatDate } : row
      );
      this.setState({ excelData: newData });
    };
  
  
    // tag functionality for multiselect functionality with dropdown and and new tag
    // function for show multiselect value from dropdown
    handleMultiSelectChange = (selectedOptions, rowIndex) => {
      const newData = this.state.excelData.map((row, index) =>
        index === rowIndex
          ? { ...row, tags: selectedOptions.map((option) => option.value) }
          : row
      );
      // const ignorError = this.ignoreFunction(newData);
      this.setState({ excelData: newData }, () => {
        this.checkForReadyState(rowIndex);
      }); //, isIgnoreErrors: ignorError
    };
  
    handleChangeAddNew = (newValue, rowIndex) => {
      const rowData = this.state.excelData[rowIndex];
      const inputValue = newValue;
      if (inputValue && !rowData.tags.includes(inputValue)) {
        this.setState({ newOption: newValue });
      }
    };
  
    changeAddNew = (val,rowIndex) => {
      console.log("chnage val",val,rowIndex)
      var arr = this.state.excelData;
      arr[rowIndex].is_new = val;
      this.setState({ excelData: arr });
    }
  
    removeCategory = (rowIndex) =>{
      console.log("remove category row",rowIndex)
      const updatedData =
                  this.state.excelData.map(
                    (row, index) =>
                      index === rowIndex
                        ? {
                          ...row,
                          isNewCategory: false,
                        }
                        : row
                  );
                this.setState({
                  excelData: updatedData,
                }, () => {
                  this.checkForReadyState(rowIndex);
                });
    }
  
  isIgstChange = (e,rowIndex) =>{
      const target = e.target;
      const value = target.checked;
  
      const updatedData =
        this.state.excelData.map(
          (row, index) =>
            index === rowIndex
              ? {
                ...row,
                is_igst: value,
                is_dirty: true,
              }
              : row
        );
      this.setState(
        { excelData: updatedData },
        () => {
          if (value) {
            const updatedData =
              this.state.excelData.map(
                (row, index) =>
                  index === rowIndex
                    ? {
                      ...row,
                      // cgst_amount: "",
                      // sgst_amount: "",
                      cgst_amount: null,
                      sgst_amount: null,
                    }
                    : row
              );
            this.setState(
              { excelData: updatedData },
              () => {
                this.calculateTotalGst(
                  rowIndex
                );
              }
            );
          } else {
            const updatedData =
              this.state.excelData.map(
                (row, index) =>
                  index === rowIndex
                    ? {
                      ...row,
                      // igst_amount: "",
                      igst_amount: null,
                    }
                    : row
              );
            this.setState(
              { excelData: updatedData },
              () => {
                this.calculateTotalGst(
                  rowIndex
                );
              }
            );
          }
        }
      );
    }
  
    handleKeyDown = (e, rowIndex) => {
      if (e.key === "Enter") {
        const rowData = this.state.excelData[rowIndex];
        // const inputValue = e.target.value.trim();
        const inputValue = {
          value: e.target.value.trim(),
          label: e.target.value.trim(),
        };
        // Check if the option already exists in the tags  state
        const optionExists = this.state.tags.some(
          (option) => option.label === inputValue.label
        );
  
        if (!masterPayload.tags.includes(inputValue.label) && !optionExists) {
          masterPayload.tags.push(inputValue.label);
        }
  
        if (!optionExists) {
          this.setState((prevState) => ({
            tags: [...prevState.tags, inputValue],
          }));
        }
        if (inputValue && !rowData.tags.includes(inputValue)) {
          const updatedTags = [...rowData.tags, inputValue];
          const newData = this.state.excelData.map((row, index) =>
            index === rowIndex ? { ...row, tags: updatedTags } : row
          );
          this.setState({ excelData: newData, newOption: "" }, () => {
            this.checkForReadyState(rowIndex);
          });
          e.target.value = "";
        }
        e.target.value = ""; // Clear the input value after adding
      }
    };
  
    // perquisit checkbox function
  
    handlePerquisitCheckboxChange = (e, rowIndex) => {
      const { checked } = e.target;
      const newData = this.state.excelData.map((row, index) =>
        index === rowIndex ? { ...row, is_perquisite: checked } : row
      );
      this.setState({ excelData: newData });
    };
  
    payloadformatDateNew = (dateString) => {
      const parts = dateString.split("-");
      // Reformat the date to "yyyy-mm-dd"
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };
  
    emailExcelData = () => {
      // // api call for send email
  
      const currentDate = new Date();
      const timestamp = currentDate
        .toISOString()
        .replace(/:/g, "-")
        .replace(/\..+/, "");
  
      const payload = {
        snapshots: [],
        file_name: `Buktec-Snapshot-Excel-Import-Inventory-Sample_${timestamp}_uploaded.xlsx`,
      };
      this.state.excelData.forEach((data) => {
        console.log("item............data", data);
        const parseIntCategory = parseInt(data.category_id);
        // console.log("check:::item.CATEGORY", item.CATEGORY)
        // console.log("check:::parseIntCategory", parseIntCategory)
  
        const categoryMatch = this.state.categories.find(
          (cat) => cat.id === parseIntCategory
        );
        // console.log("check:::categoryMatch",categoryMatch)
        const tagMap = {};
        this.state.tags.forEach((tag) => {
          tagMap[tag.value] = tag.label;
        });
  
        const tags = data.tags.map((tagValue) => tagMap[tagValue] || tagValue); // Convert tag values to labels
        const tagsString = tags.join(", "); // Join tags into a single string separated by commas
        // console.log("tags",tags)
  
        // replace type id into name
        const parseIntType = parseInt(data.type);
        console.log("check:::item.TYPE", data.type);
        // console.log("check:::parseIntType", parseIntType)
        const matchType = this.state.snapshot_types.find(
          (type) => type.id === parseIntType
        );
        console.log("check:::matchType ", matchType);
  
        const snapshot = {
          star: data.star.toUpperCase() == "YES" ? "YES" : "NO",
          party_name: data.merchant_name,
          party_gst: data.gst_no,
          party_pan: data.pan_number,
          category: categoryMatch ? categoryMatch.name : "",
          type: matchType ? matchType.name : "",
          date: this.payloadformatDateNew(data.date),
          reference_no: data.invoice_number ? data.invoice_number : "",
          total_amount: parseInt(data.total_amount) ? parseInt(data.total_amount) : 0,
          tags: tagsString,
          perquisite: data.is_perquisite === true ? "yes" : "no",
          additional_info: data.additional_info
            ? data.additional_information
            : "",
          tds_percentage: data.tds_percentage ? parseFloat(data.tds_percentage) : 0,
          msme: data.is_msme === true ? "yes" : "no",
          cgst: data.cgst_amount ? parseInt(data.cgst_amount) : 0,
          sgst: data.sgst_amount ? parseInt(data.sgst_amount) : 0,
          igst: data.igst_amount ? parseInt(data.igst_amount) : 0,
          gst_eligible: data.is_gst_claim_eligible === true ? "yes" : "no",
          status: data.STATUS,
        };
        payload.snapshots.push(snapshot);
      });
  
      console.log("check:::payload:::::download------------------", payload);
  
      SnapshotService.importExcelEmail(payload).then(async (resp_data) => {
        console.log("Email of excel data  ----------", resp_data);
        if (resp_data.response_code == 200) {
          toast.success(resp_data.message);
        } else {
          toast.error(resp_data.message);
        }
      });
    };
  
    // Helper function to split data into batches
     chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };
  
    // Process and prepare snapshot payload
     prepareSnapshots = (dataList) => {
      return dataList
        .filter((data) => !data.disabled)
        .map((data) => {
          let gst_total_amount =
            data.sgst_amount || data.cgst_amount
              ? parseFloat(data.sgst_amount) + parseFloat(data.cgst_amount)
              : data.igst_amount
              ? parseFloat(data.igst_amount)
              : null;
  
          let total_amt = parseFloat(data.total_amount || 0) - (gst_total_amount || 0);
          let tds_amount = (total_amt * parseFloat(data.tds_percentage || 0)) / 100;

          let inventory_details = {};
          let main_gst_summary = {};
          let main_hsn_summary = {}
          /*                  Inventory payload start                  */
          if(data.inventory_mode){
            console.log("inside inventory")
            let ledgers = this.SNAPSHOT_LEDGER_ENTRIES_CODE ? data.inventory_details.ledgers : []
            
            ledgers = data.inventory_details.ledgers.filter((led, index1) => {
              if (parseFloat(led.amount) > 0 || led.rate || led.guid) {
                return led;
              }
            })

            let line_items = data.inventory_details.line_items.filter((item, index1) => {
              if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {
                return item
              }
            })
            //console.log(inventory_details)
             inventory_details = {
              "line_item_total": parseFloat(data.inventory_details.line_item_total) || null,
              "line_item_gst_total": parseFloat(data.inventory_details.line_item_gst_total) || null,
              "line_item_discount_total": parseFloat(data.inventory_details.line_item_discount_total) || null,
              // "inventory_voucher_status": data.inventory_details.inventory_voucher_status ? 1 : 0,
              "inventory_voucher_status" : 1,
              // "is_common_item_ledger": data.inventory_details.is_common_item_ledger ? 1 : !data.inventory_details.inventory_voucher_status ? this.props.is_common_item_ledger : 0,
              "is_common_item_ledger": 1,
              // "accounting_narration": data.inventory_details.inventory_voucher_status ? data.inventory_details.accounting_narration || null : null,
              "accounting_narration" : null,
              "line_items": line_items.map((item, index) => {
                if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {
    
                  let line_item_update = 1;
    
                  let line_item = {
                    "line_item_id": parseInt(item.line_item_id) >= 0 ? item.line_item_id : null,
                    "line_item_name": item.line_item_name || "",
                    "hsn_sac": item.hsn_sac || null,
                    "rate": parseFloat(item.rate) || null,
                    "per": item.per || null,
                    "quantity": item.quantity || null,
                    "gst_rate": parseFloat(item.gst_rate) || null,
                    "discount_rate": parseFloat(item.discount_rate) || null,
                    "amount": parseFloat(item.amount) || null,
                    // "item_ledger_guid":data.inventory_details.inventory_voucher_status ? item.item_ledger_guid || null : null,
                    // "item_ledger_id":data.inventory_details.inventory_voucher_status ? item.item_ledger_id || null : null,
                     // "item_ledger_name":data.inventory_details.inventory_voucher_status ? item.item_ledger_name || "" : "",
                    "item_ledger_guid":null,
                    "item_ledger_id": null,
                    "item_ledger_name":"",
                    // "common_item_ledger_guid": item.common_item_ledger_guid || null,
                    // "common_item_ledger_id": item.common_item_ledger_id || null,
                    // "common_item_ledger_name": item.common_item_ledger_name || "",
                    "common_item_ledger_guid":null,
                    "common_item_ledger_id":null,
                    "common_item_ledger_name":"",
                    "line_item_update": line_item_update,
                    "line_item_id_prediction_status": 0,
                    "line_item_name_prediction_status": 0,
                    "hsn_sac_prediction_status": 0,
                    "rate_prediction_status":  0,
                    "per_prediction_status": 0,
                    "quantity_prediction_status": 0,
                    "gst_rate_prediction_status": 0,
                    "discount_rate_prediction_status": 0,
                    "amount_prediction_status": 0,
                    "item_ledger_guid_prediction_status": 0,
                    "item_ledger_id_prediction_status":   0,
                    "item_ledger_name_prediction_status": 0,
                    "common_item_ledger_guid_prediction_status": 0,
                    "common_item_ledger_id_prediction_status": 0,
                    "common_item_ledger_name_prediction_status": 0,
    
    
                  }
                  return line_item;
                }
              }) || [],
              "ledgers": ledgers.map((led, index) => {
                if (led.amount || led.rate || led.name) {
                  let ledger = {
                    "amount": parseFloat(led.amount),
                    "rate": parseFloat(led.rate),
                    "guid": led.guid,
                    "ledger_id": led.ledger_id,
                    "name": led.name,
                  }
                  return ledger;
                }
              }),
              "sync_datetime": data.inventory_details.inventory_voucher_status ? changeDateTimeFormat(new Date()) : null,
              "sync_error": null,
              "sync_status": 0,
              // "voucher_total_amount": data.inventory_details.inventory_voucher_status ? parseFloat(data.inventory_details.voucher_total_amount) || null : null,
              "voucher_total_amount" : null,
              "voucher_creation_date": data.inventory_details.voucher_creation_date ? changeDateFormate(new Date(data.inventory_details.voucher_creation_date)) : null,
              // "party_ledger_guid": data.inventory_details.inventory_voucher_status ? data.inventory_details.party_ledger_guid || null : null,
              // "party_ledger_id": data.inventory_details.inventory_voucher_status ? data.inventory_details.party_ledger_id || null : null,
              // "party_ledger_name": data.inventory_details.inventory_voucher_status ? data.inventory_details.party_ledger_name || null : null,
              // "common_item_ledger_guid": data.inventory_details.inventory_voucher_status ? data.inventory_details.common_item_ledger_guid || null : null,
              // "common_item_ledger_id": data.inventory_details.inventory_voucher_status ? data.inventory_details.common_item_ledger_id || null : null,
              // "common_item_ledger_name": data.inventory_details.inventory_voucher_status ? data.inventory_details.common_item_ledger_name || null : null,
               "party_ledger_guid":  null,
              "party_ledger_id": null,
              "party_ledger_name":  null,
              "common_item_ledger_guid":  null,
              "common_item_ledger_id": null,
              "common_item_ledger_name":null,
              "party_ledger_guid_prediction_status": 0,
              "party_ledger_id_prediction_status": 0,
              "party_ledger_name_prediction_status": 0,
              "common_item_ledger_guid_prediction_status": 0,
              "common_item_ledger_id_prediction_status": 0,
              "common_item_ledger_name_prediction_status": 0,
              // "parent_voucher_type_id":data.inventory_details.inventory_voucher_status ? data.inventory_details.parent_voucher_type_id || null : null ,
              // "voucher_type_guid": data.inventory_details.inventory_voucher_status ? data.inventory_details.voucher_type_guid || null : null,
              // "voucher_type_name": data.inventory_details.inventory_voucher_status ? data.inventory_details.voucher_type_name || null : null,
              // "voucher_type_id": data.inventory_details.inventory_voucher_status ? data.inventory_details.voucher_type_id || null : null,
               "parent_voucher_type_id": null ,
              "voucher_type_guid": null,
              "voucher_type_name": null,
              "voucher_type_id":null,
            
            }
    
    
        //SOC:: gst and hsn sac summary
        let gst_summary = [];
        let hsn_summary = [];
        let gst_details = [];
        let hsn_details = [];

        line_items.forEach((item, index) => {
          if (item.gst_rate) {
            if (!gst_summary[item.gst_rate]) {
              gst_summary[item.gst_rate] = 0;
            }
            gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
          }

          if (item.hsn_sac) {
            let hsn_sac = item.hsn_sac;
            if (!hsn_summary[item.hsn_sac]) {

              hsn_summary[hsn_sac] = {};
              hsn_summary[hsn_sac].amt = 0;
            }

            hsn_summary[hsn_sac].amt += parseFloat(item.amount) || 0;

            if (!hsn_summary[hsn_sac].cgst_rate)
              hsn_summary[hsn_sac].cgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

            if (!hsn_summary[hsn_sac].sgst_rate)
              hsn_summary[hsn_sac].sgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

            if (!hsn_summary[hsn_sac].igst_rate)
              hsn_summary[hsn_sac].igst_rate = this.state.is_igst ? item.gst_rate * 1 : 0;

            hsn_summary[hsn_sac].hsn_sac = item.hsn_sac;
          }
        })

        let numeric_hsn_array = new Array();
        for (var items in hsn_summary) {
          numeric_hsn_array.push(hsn_summary[items]);
        }

        gst_summary.forEach((amt, index) => {
          let cgst_amount = !data.is_igst ? amt * 0.5 : null;
          let sgst_amount = !data.is_igst ? amt * 0.5 : null;
          let igst_amount = data.is_igst ? amt : null;

          gst_details.push({
            gst_rate: index,
            gst_amount: parseFloat(parseFloat(amt).toFixed(2)),
            cgst_amount: data.is_igst ? null : parseFloat(parseFloat(cgst_amount).toFixed(2)),
            sgst_amount: data.is_igst ? null : parseFloat(parseFloat(sgst_amount).toFixed(2)),
            igst_amount: !data.is_igst ? null : parseFloat(parseFloat(igst_amount).toFixed(2)),
          })
        })

        numeric_hsn_array.forEach((item, index) => {
          let gst_amount = 0;
          let cgst_amount = !data.is_igst ? item.amt * item.cgst_rate / 100 : null;
          let sgst_amount = !data.is_igst ? item.amt * item.sgst_rate / 100 : null;
          let igst_amount = data.is_igst ? item.amt * item.igst_rate / 100 : null;

          gst_amount += data.is_igst ? igst_amount || 0 : (cgst_amount || 0) + (sgst_amount || 0),

            hsn_details.push({
              "cgst_rate": item.cgst_rate || null,
              "sgst_rate": item.sgst_rate || null,
              "igst_rate": item.igst_rate || null,
              "gst_amount": parseFloat(parseFloat(gst_amount).toFixed(2)),
              "cgst_amount": cgst_amount ? parseFloat(parseFloat(cgst_amount).toFixed(2)) : null,
              "sgst_amount": sgst_amount ? parseFloat(parseFloat(sgst_amount).toFixed(2)) : null,
              "igst_amount": igst_amount ? parseFloat(parseFloat(igst_amount).toFixed(2)) : null,
              "hsn_sac": item.hsn_sac,
              "basic_amount": parseFloat(parseFloat(item.amt).toFixed(2)),
            })
        })

        let gst_total = 0, total_cgst_amount = 0, total_sgst_amount = 0, total_igst_amount = 0;

        gst_details.map((item, index) => {
          total_cgst_amount += parseFloat(parseFloat(item.cgst_amount).toFixed(2)) || 0;
          total_sgst_amount += parseFloat(parseFloat(item.sgst_amount).toFixed(2)) || 0;
          total_igst_amount += parseFloat(parseFloat(item.igst_amount).toFixed(2)) || 0;
        })

        gst_total = parseFloat(parseFloat(total_cgst_amount + total_sgst_amount + total_igst_amount).toFixed(2));


        let hsn_sac_basic_total = 0, hsn_sac_cgst_total = 0, hsn_sac_sgst_total = 0, hsn_sac_igst_total = 0, hsn_sac_gst_total = 0;

        hsn_details.map((item, index) => {
          hsn_sac_basic_total += parseFloat(parseFloat(item.basic_amount).toFixed(2)) || 0;
          hsn_sac_cgst_total += parseFloat(parseFloat(item.cgst_amount).toFixed(2)) || 0;
          hsn_sac_sgst_total += parseFloat(parseFloat(item.sgst_amount).toFixed(2)) || 0;
          hsn_sac_igst_total += parseFloat(parseFloat(item.igst_amount).toFixed(2)) || 0;
          hsn_sac_gst_total += parseFloat(parseFloat(item.gst_amount).toFixed(2)) || 0;
        })

        main_gst_summary = gst_total > 0 ? {
          "cgst_total": data.is_igst ? null : total_cgst_amount,
          "sgst_total": data.is_igst ? null : total_sgst_amount,
          "igst_total": data.is_igst ? total_igst_amount : null,
          "gst_total": gst_total,
          "gst_details": gst_details
        } : null;

        main_hsn_summary = hsn_sac_gst_total > 0 ? {
          "basic_total": hsn_sac_basic_total,
          "cgst_total": data.is_igst ? null : hsn_sac_cgst_total,
          "sgst_total": data.is_igst ? null : hsn_sac_sgst_total,
          "igst_total": data.is_igst ? hsn_sac_igst_total : null,
          "gst_total": hsn_sac_gst_total,
          "hsn_sac_details": hsn_details
        } : null;
          }else{
            inventory_details = null;
            main_gst_summary = null;
            main_hsn_summary = null;
          }
         
            

          /*              Inventory payload end                                               */
          return {
            is_star: data.star.toUpperCase() == "YES" ? 1 : 0,
            expense: data.expense, // Assuming CATEGORY maps to expense
            merchant_type: data.merchant_type ? data.merchant_type : 2, // Assuming a fixed value for merchant_type
            type: data.type ? data.type : 8, // Assuming a fixed value for type
            date: this.payloadformatDateNew(data.date), // Assuming a fixed value for date
            merchant_update: 1, // Assuming a default value for merchant_update
            merchant_name: data.merchant_name ? data.merchant_name : null, // Assuming PARTY NAME maps to merchant_name
            merchant_id: data.merchant_id ? data.merchant_id : 0, // Assuming a default value for merchant_id
            gst_no: data.gst_no, // Assuming PARTY GST maps to gst_no
            invoice_number: data.invoice_number ? data.invoice_number : "", // Assuming an empty string for invoice_number
            // total_amount: parseInt(data.total_amount) ? parseInt(data.total_amount) :
            //   0, // Assuming a default value for total_amount
            total_amount: parseFloat(data.total_amount) ? parseFloat(data.total_amount) :
            0, // Assuming a default value for total_amount
            category_id: data.category_id
              ? parseInt(data.category_id)
              : null, // Assuming a default value for category_id
            tags: data.tags, // Using TAGS directly
            additional_information: data.additional_information
              ? data.additional_information
              : "", // Assuming an empty string for additional_information
            // Assuming default values for the rest of the properties
            payment_status: 2,
            payment_total: 0,
            payment_information: [],
            mode: 1,
            file_url: "",
            pan_number: data.pan_number,
            gst_no_prediction_status: 0,
            total_amount_prediction_status: 0,
            igst_amount_prediction_status: 0,
            sgst_amount_prediction_status: 0,
            cgst_amount_prediction_status: 0,
            date_prediction_status: 0,
            merchant_name_prediction_status: 0,
            snapshot_number_prediction_status: 0,
            merchant_id_prediction_status: 0,
            pan_number_prediction_status: 0,
            snapshot_type_prediction_status: 0,
            expense_prediction_status: 0,
            category_id_prediction_status: 0,
            tds_percentage_prediction_status: 0,
            tags_prediction_status: 0,
            is_msme_prediction_status: 0,
            is_entity_mismatch_prediction_status: 0,
            approval_comment: null,
            approval_status: null,
            approved_amount: null,
            new_tags: [],
            voucher_status: 0,
            is_duplicate: 0,
            prediction_id: null,
            source: 4,
            synced_by_user: null,
            entry_status: this.state.isReady ? 2 : 1,
            accounting_status: 3,
            is_msme: data.is_msme === true ? 1 : 0,
            is_perquisite: data.is_perquisite === true ? 1 : 0,
            is_gst_claim_eligible:
              data.is_gst_claim_eligible === true ? 1 : 0,
            is_same_invoice: 0,
            is_entity_mismatch: 0,
            is_reimbursement: 0,
            reimbursement_narration: null,
            inventory_mode: data.inventory_mode ? 1 : 0,
            inventory_details: inventory_details,
            gst_summary: main_gst_summary,
            hsn_sac_summary: main_hsn_summary,
            gst_status:
              (data.sgst_amount && data.cgst_amount) || data.igst_amount
                ? 1
                : 0,
            sgst_amount: data.sgst_amount
              ? parseFloat(data.sgst_amount)
              : null,
            cgst_amount: data.cgst_amount
              ? parseFloat(data.cgst_amount)
              : null,
            igst_amount: parseFloat(data.igst_amount)
              ? parseFloat(data.igst_amount)
              : null,
            gst_total_amount:
              data.sgst_amount || data.cgst_amount
                ? parseFloat(data.sgst_amount) + parseFloat(data.cgst_amount)
                : data.igst_amount
                  ? parseFloat(data.igst_amount)
                  : null,
            tds_status: data.tds_percentage ? 1 : 0,
            tds_details: [{
              base_amount: total_amt,
              tds_amount: tds_amount,
              tds_percentage: (data.tds_percentage
                ? (data.tds_percentage)
                : 0),
            }],
            // tds_percentage: data.tds_percentage
            //   ? parseInt(data.tds_percentage)
            //   : null,
            tds_total_amount: tds_amount || null,
            has_igst: parseFloat(data.igst_amount) ? 1 : 0,
            vouchers: [],
            snapshot_type_update: data.merchant_id ? 1 : 0,
            is_gst_override:data.gst_override ? 1 : 0,
            amount_round_off:this.props.amount_round_off,
            request_id : null,
            excel_filename : this.state.fileName
          };
        });
    };
  
  
    // Main function to upload data in batches
   uploadInBatches = async () => {
    const allSnapshots = this.prepareSnapshots(this.state.excelData);
    console.log("createevidenceform",allSnapshots);
    const batches = this.chunkArray(allSnapshots, BATCHSIZE); // Divide data into chunks of 300
  
    let updatedExcelData = [...this.state.excelData];
    let globalIndex = 0; // Keep track of position across batches
  
    for (const batch of batches) {
      const payload = { snapshots: batch };
  
      try {
        const resp_data = await SnapshotService.importExcelUpload(payload);
        console.log("Batch Upload Response : ",batch.length, resp_data);
  
        if (resp_data.response_code === 200) {
          const batchResponses = resp_data.result.snapshots;
          //this.updateExcelData(resp_data.result.snapshots);
          // Update the excelData with the batch responses
          let cnt = -1;
          updatedExcelData = updatedExcelData.map((item, index) => {
            if (item.disabled) {
              return { ...item, STATUS: "Deleted" };
            }
            cnt++;
            // Ensure correct mapping with global index
            if (
              index >= globalIndex &&
              ((index <= globalIndex + batch.length) || (index >= globalIndex + batch.length))
            ) {
              // const response = batchResponses[index - globalIndex];
              const response = batchResponses[cnt];
             
              let updatedStatus = "Error 104: Could not create snapshot";
  
              if (response?.response_code === 200) {
                updatedStatus = "Uploaded";
              } else if (response?.response_code === 400) {
                if (response?.reason.is_duplicate) {
                  updatedStatus = "Duplicate Snapshot";
                } else if (response?.reason.is_same_invoice) {
                  updatedStatus = "Is Same Invoice";
                }
              }
  
              return { ...item, STATUS: updatedStatus };
            }
  
            return item; // Keep other items unchanged
          });
  
          globalIndex += batch.length; // Move to the next batch index
  
  
        } else {
          toast.error(resp_data.message);
        }
      } catch (error) {
        console.error("Batch Upload Error: ", error);
        toast.error("An error occurred during batch upload.");
      }
    }
  
      // Call emailExcelData after the last batch is processed
      console.log("All batches processed. Sending email...",updatedExcelData);
      //this.emailExcelData();
       // Ensure updated state is set after all batches
       this.setState(
        {
          is_dirty:false,
          excelData: updatedExcelData,
          isUploading: false,
          isDownload: true,
          uploading_file_percent: 100,
          isSelectFile:false
        }
        ,
        () => this.emailExcelData()
      );
  };
  
  // Update excel data with upload status
   updateExcelData = (responses) => {
    let cnt = -1;
    const updatedExcelData = this.state.excelData.map((data) => {
      if (data.disabled) {
        return { ...data, STATUS: "Deleted" };
      } else {
        cnt++;
        const response = responses[cnt];
  console.log("updateExcelData ------------------ ", response)
        if (response?.response_code === 200) {
          return { ...data, STATUS: "Uploaded" };
        } else if (response?.response_code === 400) {
          console.log("11111111111111111");
          if (response?.reason?.is_duplicate) {
            console.log("22222222222222222");
            return { ...data, STATUS: "Duplicate Snapshot" };
          } else if (response?.reason?.is_same_invoice) {
            console.log("3333333333333333333333");
            return { ...data, STATUS: "Is Same Invoice" };
          } else {
            return { ...data, STATUS: "Error: Could not create snapshot" };
          }
        }
      }
      return data; // No changes for other cases
    });
  console.log("updated excle data --------------" , updatedExcelData);
    this.setState(
      {
        excelData: updatedExcelData,
        isUploading: false,
        isDownload: true,
        uploading_file_percent: 100,
      }
      // ,
      // () => this.emailExcelData()
    );
  };
  
    handleUpload = () => {
      const payload = {
        snapshots: [],
      };
  
      // Your upload logic goes here
      this.setState({ isUploading: true ,isSelectFile:true});
  
      console.log("Master PAyload ---------", masterPayload , this.state.excelData.length);
  
      // api call
      SnapshotService.importExcelMasterSnapshot(masterPayload).then(
        async (resp_data) => {
          console.log("master payload response ------------------", resp_data);
          if (resp_data.response_code == 200) {
            // toast.success(`${resp_data.message} created`);
            // console.log("response:::200::", resp_data.result);
            this.setState({
              masterSnapshotResponse: resp_data.result,
              uploading_file_percent: 50,
            });
            const masterData = resp_data.result;
  
            console.log(
              "---- merchant list --------- ",
              this.state.all_merchant_list
            );
            // update added new party name id in merchant_list state and exceldata state
            const updateMerchantList = this.state.all_merchant_list.map(
              (newMerchant) => {
                const matchingMerchantState =
                  masterData.merchants &&
                  masterData?.merchants?.find(
                    (stateMerchant) => stateMerchant.name === newMerchant.label
                  );
  
                const merchantID = matchingMerchantState
                  ? matchingMerchantState.id
                  : newMerchant.value;
                const merchantType = matchingMerchantState
                  ? matchingMerchantState.type
                  : newMerchant.type;
                const merchantPan = matchingMerchantState
                  ? matchingMerchantState.pan_number
                  : newMerchant.pan_number;
                const merchantGst = matchingMerchantState
                  ? matchingMerchantState.gst_no
                  : newMerchant.gst_no;
                return {
                  ...newMerchant,
                  value: merchantID,
                  id: merchantID,
                  type: merchantType,
                  pan_number: merchantPan,
                  gst_no: merchantGst,
                };
              }
            );
  
            this.setState({ all_merchant_list: updateMerchantList });
  
            const updatedMerchantListInExcelData = this.state.excelData.map(
              (data) => {
                // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
                // console.log("updatedMerchantListInExcelData:::dtaa", data);
                const matchingMerchantState =
                  masterData.merchants &&
                  masterData?.merchants?.find(
                    (stateMerchant) => stateMerchant.name === data.merchant_name
                  );
                console.log(
                  "matchingMerchantState:::exceldata::",
                  matchingMerchantState
                );
                const merchantID = matchingMerchantState
                  ? matchingMerchantState.id
                  : data.merchant_id;
                // const merchantID = matchingMerchantState ? matchingMerchantState.id:"";
                const merchantType = matchingMerchantState
                  ? matchingMerchantState.type
                  : data.merchant_type;
                const merchantPan = matchingMerchantState
                  ? matchingMerchantState.pan_number
                  : data.pan_number;
                const merchantGst = matchingMerchantState
                  ? matchingMerchantState.gst_no
                  : data.gst_no;
                // console.log("merchantID::", merchantID);
                // console.log("matchingCategoryState::", matchingCategoryState)
                // console.log("matchingCategoryState::", categoryId)
                return {
                  ...data,
                  gst_no: merchantGst, // Prefill gst_no
                  pan_number: merchantPan, // Prefill pan_number
                  merchant_id: merchantID,
                  merchant_type: merchantType,
                };
              }
            );
            this.setState({ excelData: updatedMerchantListInExcelData });
  
            // update added new category id in category state and exceldata state
            const updatedCategories = this.state.categories.map((category) => {
              // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
              const matchingCategoryState =
                masterData.categories &&
                masterData?.categories?.find(
                  (stateCategory) => stateCategory.name === category.name
                );
              const categoryId = matchingCategoryState
                ? matchingCategoryState.id
                : category.id;
              // console.log("matchingCategoryState::", matchingCategoryState)
              // console.log("matchingCategoryState::", categoryId)
              return {
                ...category,
                id: categoryId,
              };
            });
            this.setState({ categories: updatedCategories });
  
            const updatedCategoryInExcelData = this.state.excelData.map(
              (data) => {
                // console.log(
                //   "this.state.masterSnapshotResponse.categories :::",
                //   masterData.categories
                // );
                const matchingCategoryState =
                  masterData.categories &&
                  masterData?.categories?.find(
                    (stateCategory) => stateCategory.name === data.category_id
                  );
                const categoryId = matchingCategoryState
                  ? matchingCategoryState.id
                  : data.category_id;
                // console.log("matchingCategoryState::", matchingCategoryState);
                // console.log("matchingCategoryState::", categoryId);
                return {
                  ...data,
                  category_id: categoryId,
                };
              }
            );
            this.setState({ excelData: updatedCategoryInExcelData });
  
            console.log("updated tags -------------------1 ", masterData.tags);
            // update added new tags id in tags state and exceldata state
            const updatedTags = this.state.tags.map((tag) => {
              // console.log("this.state.masterSnapshotResponse.categories :::", masterData.categories )
              const matchingTagsState =
                masterData.tags &&
                masterData?.tags?.find((stateTag) => stateTag.name === tag.label);
              const tagsId = matchingTagsState ? matchingTagsState.id : tag.value;
              console.log("updated tags -------------------2 ", tagsId);
              // console.log("matchingCategoryState::", matchingCategoryState)
              // console.log("matchingCategoryState::", categoryId)
              return {
                ...tag,
                value: tagsId,
              };
            });
            console.log("updated tags -------------------3 ", updatedTags);
            this.setState({ tags: updatedTags });
  
            const updatedTagsInExcelData = this.state.excelData.map((data) => {
              const updatedTags = data.tags.map((tagId) => {
                const tag =
                  masterData.tags &&
                  masterData?.tags?.find((tag) => tag.name === tagId);
                return tag ? tag.id : tagId;
              });
              return { ...data, tags: updatedTags };
            });
            console.log(
              "updated tags -------------------4 ",
              updatedTagsInExcelData
            );
            this.setState({ excelData: updatedTagsInExcelData });
  
            // Start batch upload
            this.uploadInBatches();
            
          } else if (resp_data.response_code == 400) {
            toast.error(resp_data.reason.pan_number);
            const duplicat = this.validationMatching(this.state.excelData);
            const duplicatignore = this.ignoreFunction(this.state.excelData);
            this.setState({
              isUploading: false,
              isSelectFile:false,
              uploading_file_percent: 0,
              errors: duplicat,
              isIgnoreErrors: duplicatignore,
            });
          } else {
            toast.error(resp_data.message);
          }
        }
      );
    };
  
    downloadExcel() {
      // Map each tag value to its corresponding label for easy lookup
      const tagMap = {};
      this.state.tags.forEach((tag) => {
        tagMap[tag.value] = tag.label;
      });
  
      const updatedData = [];
      this.state.excelData.map((item) => {
        // console.log("item",item)
        const parseIntCategory = parseInt(item.category_id);
  
        const categoryMatch = this.state.categories.find(
          (cat) => cat.id === parseIntCategory
        );
        // console.log("check:::categoryMatch",categoryMatch)
  
        const tags = item.tags.map((tagValue) => tagMap[tagValue] || tagValue); // Convert tag values to labels
        const tagsString = tags.join(", "); // Join tags into a single string separated by commas
        // console.log("tags",tags)
  
        // replace type id into name
        const parseIntType = parseInt(item.type);
        console.log("check:::item.TYPE", item.type);
        // console.log("check:::parseIntType", parseIntType)
        const matchType = this.state.snapshot_types.find(
          (type) => type.id === parseIntType
        );
        console.log("check:::matchType", matchType);
  
        // console.log("check:::matchType", matchType)
  
        // Compute values for MSME, PERQUISITE, and GST ELIGIBLE
        const msmeStatus = item.is_msme ? "Yes" : "No";
        const perquisiteStatus = item.is_perquisite ? "Yes" : "No";
        const gstEligibleStatus = item.is_gst_claim_eligible ? "Yes" : "No";
        const allStatusesAreNo =
          !item.is_msme && !item.is_perquisite && !item.is_gst_claim_eligible;

          //line item 

          
  
        // Final object assembly

        const getCategoryName = (categoryId) =>
        this.state.categories.find((cat) => parseInt(cat.id) === parseInt(categoryId))?.name || "";

        const createBaseRow = (item) => ({
        STAR: item.star.toUpperCase() === "YES" ? "Yes" : "No",
        "PARTY NAME": item.merchant_name,
        "PARTY GST": item.gst_no,
        "PARTY PAN": item.pan_number,
        CATEGORY: getCategoryName(item.category_id),
        TYPE: matchType?.name || "",
        DATE: this.payloadformatDateNew(item.date),
        "REFERENCE NO": item.invoice_number,
        "TOTAL AMOUNT": item.total_amount ? parseInt(item.total_amount) : "",
        TAGS: tagsString,
        PERQUISITE: allStatusesAreNo ? "No" : perquisiteStatus,
        "ADDITIONAL INFO": item.additional_information,
        MSME: allStatusesAreNo ? "No" : msmeStatus,
        CGST: item.cgst_amount ? parseInt(item.cgst_amount) : "",
        SGST: item.sgst_amount ? parseInt(item.sgst_amount) : "",
        IGST: item.igst_amount ? parseInt(item.igst_amount) : "",
        "GST ELIGIBLE": allStatusesAreNo ? "No" : gstEligibleStatus,
        "TDS %": item.tds_percentage || "",
        STATUS: item.STATUS,
        PRODUCTS: "",
        HSN: "",
        "GST RATE": "",
        QUANTITY: "",
        RATE: "",
        PER: "",
        "TAXABLE VALUE": "",
      });

      const createLineItemRow = (line) => ({
        "PARTY NAME": "",
        CATEGORY: "",
        "TOTAL AMOUNT": "",
        PRODUCTS: line.line_item_name,
        HSN: line.hsn_sac,
        "GST RATE": line.gst_rate,
        QUANTITY: line.quantity,
        RATE: line.rate,
        PER: line.per,
        "TAXABLE VALUE": line.amount,
      });

     if(item.inventory_mode === true && item.inventory_details?.line_items.length > 0){
         item.inventory_details.line_items.forEach((line, index) => {
            if (index === 0) {
              updatedData.push({
                ...createBaseRow(item),
                PRODUCTS: line.line_item_name,
                HSN: line.hsn_sac,
                "GST RATE": line.gst_rate,
                QUANTITY: line.quantity,
                RATE: line.rate,
                PER: line.per,
                "TAXABLE VALUE": line.amount,
              });
            } else {
              updatedData.push(createLineItemRow(line));
            }
          })
      }else updatedData.push(createBaseRow(item));

      });
  
      console.log("check:::updatedData:::", updatedData);
  
      // console.log("check:::download:::updatedData", updatedData);
      // Create a new workbook
      const wb = XLSX.utils.book_new();
  
      // Convert JSON data to worksheet
      const ws = XLSX.utils.json_to_sheet(updatedData);
  
      // Customize header style to make it bold
      const headerStyle = { bold: true };
      // const headerStyle = {
      //   font: {
      //     bold: true,
      //   },
      // };
  
      const range = XLSX.utils.decode_range(ws["!ref"]);
      // console.log('check:::range', range);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const headerCell = XLSX.utils.encode_cell({ r: range.s.r, c: C });
        ws[headerCell].s = headerStyle;
      }
  
      // for (let C = range.s.c; C <= range.e.c; ++C) {
      //   const cellAddress = { c: C, r: range.s.r };
      //   const cellRef = XLSX.utils.encode_cell(cellAddress);
      //   if (!ws[cellRef]) continue; // Skip empty cells
      //   ws[cellRef].s = headerStyle;
      // }
  
      // console.log('check:::range', range);
      // console.log('check:::ws', ws);
  
      // Set column widths
      ws["!cols"] = [
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];
  
      const currentDate = new Date();
      const timestamp = currentDate
        .toISOString()
        .replace(/:/g, "-")
        .replace(/\..+/, ""); // Format: YYYY-MM-DDTHH-MM-SS
  
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
      // Write the workbook to a file
      XLSX.writeFile(
        wb,
        `Buktec-Snapshot-Excel-Import-Inventory-Sample_${timestamp}_uploaded.xlsx`
      );
    }
  
    downloadSampleExcelFile() {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = excelFile;
      link.download = "Buktec-Snapshot-Excel-Import-Inventory-Sample.xlsx"; // Set the file name for download
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      document.body.removeChild(link);
    }

  render() {
    const { excelData, isUploading } = this.state;
     console.log("arrrayyy",this.state.excelData)
    return (
      <main className="content-wrapper-new import-excel-popup">
        <Gstr2bRouteLeavingGuard
              when={this.state.is_dirty}
              saveChanges={this.saveBeforeLeave}
              closed={this.state.closed}
              closeAction={this.closeAction}
              />
        <div className="container-fluid container-padding-new">
          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <BreadCrum level={2} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <h2 className="breadcrumb-title-new">Excel inventory</h2>
                    </div>
                    <div className="col-7">
                      {/* Select File.. */}
                      <div className="ie_upload_file">
                        <input
                          type="file"
                          id="file-upload"
                          accept=".xlsx, .xls"
                          onChange={this.handleDrop}
                        />
                        <label
                          for="file-upload"
                          class="btn btn-success apply_btn_new mb-0"
                        >
                          {" "}
                          Select File{" "}
                        </label>
                        <p className="ml-3 mb-0">
                          File Name:{" "}
                          <span>
                            {" "}
                            {this.state.fileName
                              ? this.state.fileName
                              : "No File Selected"}
                          </span>
                          {this.state.selectFileError && (
                            <>
                              <br />
                              <span style={{ color: "red" }}>
                                {this.state.selectFileError}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                      {/* End Select File.. */}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-end gap-10">
                      <h2 className="breadcrumb-title-new sub-header-cicon">
                        <Button variant="outline-success reset_btn_new ml-auto" onClick={this.downloadSampleExcelFile}>
                          <span class="icon-Download reset-download-icon mr-2"></span>
                          Sample Excel File
                        </Button>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

           {/* Loader */}
        {this.state.isSelectFile && (
          <>
            <div className="ie_loader_overlay"></div>
            <img className="ie_loader_img" src={loaderw} />
          </>
        )}
        {/* End Loader */}

          {/* Table Design  */}
          <ReactVirtualized 
          excelData={excelData}
          isDownload = {this.state.isDownload}
          isUploading = {this.state.isUploading}
          handleStarClick = {this.handleStarClick}
          onMerchantChanged = {this.onMerchantChanged}
          all_merchant_list = {this.state.all_merchant_list}
          changeAddNew = {this.changeAddNew}
          checkForReadyState= {this.checkForReadyState}
          handleChange = {this.handleChange}
          onGSTChange = {this.onGSTChange}
          amountChange = {this.amountChange}
          calculateTotalGst = {this.calculateTotalGst}
          handleNewCategorySubmit = {this.handleNewCategorySubmit}
          handleCategoryChange = {this.handleCategoryChange}
          removeCategory = {this.removeCategory}
          categories = {this.state.categories}
          snapshot_types = {this.state.snapshot_types}
          handleTypeChange = {this.handleTypeChange}
          handleDateChange = {this.handleDateChange}
          calculateTDS = {this.calculateTDS}
          tags = {this.state.tags}
          handleMultiSelectChange = {this.handleMultiSelectChange}
          handleChangeAddNew = {this.handleChangeAddNew}
          handleKeyDown = {this.handleKeyDown}
          customStyles = {this.customStyles}
          isIgstChange = {this.isIgstChange}
          handleIgnore = {this.handleIgnore}
          handleDisable = {this.handleDisable}
          handleRestore = {this.handleRestore}
          amountRoundOff = {this.amountRoundOff}
          handleGstOverrideChange = {this.handleGstOverrideChange}
          voucherTypeList = {this.state.voucherTypeList}
          ledgerNameList = {this.state.ledgerNameList}
          item_ledger_list = {this.state.item_ledger_list}
          line_items_list = {this.state.line_items_list}
          is_common_item_ledger = {this.props.is_common_item_ledger}
          saveLineItemData = {this.saveLineItemData}
          SNAPSHOT_LEDGER_ENTRIES_CODE = {this.SNAPSHOT_LEDGER_ENTRIES_CODE}
          VIEW_ONLY_SNAPSHOT={this.VIEW_ONLY_SNAPSHOT}
          convertToFullDate = {this.convertToFullDate}
          getNarration = {this.getNarration}
          />
          {/* End Table Design  */}

          {/* Download and Status */}
          <div className="row status_arrow_btn">

            <div className="col-xl-12 col-lg-12 col-md-12 mt-0 mt-lg-0 mt-md-3 text-right d-flex align-items-center justify-content-end bottom-save-btn">
            {/* <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center"> */}
              {/* key shortcuts */}
              {/* {this.state.excelData.length > 0 ? (
                <div className="ie_arrows">
                  <span
                    class="icon-right-arrow ie_arrows_icon ie_left"
                    onClick={() => this.scroll("left")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon ie_right"
                    onClick={() => this.scroll("right")}
                  ></span>
                  <span
                    class="icon-right-arrow-1 ie_arrows_icon pt-1"
                    onClick={() => this.scroll("down")}
                  ></span>
                  <span
                    class="icon-right-arrow ie_arrows_icon"
                    onClick={() => this.scroll("up")}
                  ></span>
                </div>
              ) : null} */}
              {/* End key shortcuts */}
              <div className="ie_icon_up_btn">
                    
                      {this.shouldEnableIgnoreButton(this.state.excelData) ? 
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              id={`tooltip-disabled-${1}`}
                            >
                              Ignore All
                            </Tooltip>
                          }
                        >
                            <span
                              class="icon-warning ie_icon_btn yellow-icon"
                              onClick={()=> {
                                this.handleIgnoreAll()
                                
                              }}
                            ></span>
                        </OverlayTrigger> : (
                          <span class="icon-warning ie_icon_btn gray-icon cmenu"></span>
                        )
                   }    
                        
                      
                </div>

            {/* </div> */}

              {this.state.isDownload && (
                <Button variant="success apply_btn_new mr-3"  onClick={() => this.downloadExcel()}>Download</Button>
              )}

              <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center mr-3">
                <span className="toggle-btn-sec-text mr-4">Status</span>
                <span className="toggle-switch-center">
                  <span className="toggle-btn-sec-text">Draft</span>
                  <label className="switch">
                    <input
                      disabled={this.state.isDownload}
                      type="checkbox"
                      checked={this.state.isReady}
                      value={this.state.isReady}
                      onChange={(e) => {
                        const target = e.target;
                        this.setState({ isReady: target.checked,isSelectFile:true }, () => {
                          // this.checkForReadyState(0);
                          // if(this.state.isReady){
                          //   setTimeout(async()=>{
                          //     await this.checkForDraftToReadyState();
                          //     this.setState({isSelectFile:false})
                          //   },0)
                          // }else{
                          //   setTimeout(()=>{
                          //     const updatedData = this.state.excelData.map((row, index) =>{
                          //       let updatedRow = {...row}
                          //       updatedRow.entry_status = 1;
                          //       updatedRow.is_dirty = true;
                          //       return updatedRow
                          //     }
                               
                          //     );
                          //     this.setState({ excelData: updatedData ,isSelectFile:false}, () => {})
                          //   },0)
                            
                          // }
                          setTimeout(async()=>{
                                await this.checkForDraftToReadyState();
                                this.setState({isSelectFile:false})
                              },0)
                          
                        });
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="toggle-btn-sec-text">Ready</span>
                </span>
              </div>

              <div className="bottom_save_btn">
                {this.state.isDownload ? (
                  <Button
                    variant="success apply_btn_new"
                    onClick={() => this.props.history.push('/snapshot/evidence')}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    variant="success apply_btn_new"
                    onClick={this.handleUpload}
                    disabled={
                      isUploading ||
                      excelData.length === 0 ||
                      this.state.uploading_file_percent > 0 ||
                      !excelData.every((item) =>
                        item.errors
                          ? Object.keys(item.errors).length === 0
                          : true
                      ) ||
                      !excelData.every((item) =>
                        item.ignoreErrors
                          ? Object.keys(item.ignoreErrors).length === 0
                          : true
                      ) || !excelData.every((item) => item.inventory_errors? Object.keys(item.inventory_errors).length === 0 : true) ||
                      // Object.keys(this.state.errors).length > 0 ||
                      // Object.keys(this.state.isIgnoreErrors).length > 0 ||
                      excelData.filter((item) => item.disabled).length ===
                      excelData.length // Disable button if all items are disabled
                    } // Disable upload button while uploading
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* End Download and Status */}
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  const session_values = state.Session || {};
  const entity_gst_no = session_values.entity_config_param.gst_no || null;
  const amount_round_off = session_values.entity_config_param.amount_round_off;
  const features_permissions_list =
    session_values.features_permissions_list || [];
  const accounting_mode =
    session_values.entity_config_param.accounting_mode || 1;
    const is_common_item_ledger = session_values.entity_config_param.is_common_item_ledger;
    const is_service_description_voucher = session_values.entity_config_param.is_service_description_voucher;
    
  return {
    entity_gst_no,
    features_permissions_list,
    accounting_mode,
    amount_round_off,
    is_common_item_ledger,is_service_description_voucher
  };
}

export default connect(mapStateToProps)(withRouter(ExcelInventory));
